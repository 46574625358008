/* eslint-disable no-catch-shadow */
/* eslint-disable dot-notation */
import axios from "axios";
import { store } from "./store";
import { setLoginState, setLogout } from "./redux/authSlice";
const AUTH_URL = process.env.REACT_APP_BASE;

// const AUTH_URL = process.env.REACT_APP_AUTH_BASE;
const axiosInstance = axios.create({
  timeout: 15000,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(async (req) => {
  const {
    auth: { login_Data },
  } = store.getState();
  try {
    const currentTime = new Date().getTime();
    if (currentTime > login_Data?.exp) {
      const body = {
        refreshToken: login_Data?.refreshToken,
      };
      const { data } = await axios.post(`${AUTH_URL}/auth/refreshToken`, body);
      const newData = {
        ...data,
        token: data?.accessToken,
      };
      const { dispatch } = store;
      dispatch(setLoginState(newData));
      req.headers["Authorization"] = `Bearer ${login_Data?.accessToken}`;
      return req;
    }
  } catch (error) {
    const { dispatch } = store;
    dispatch(setLogout());
  }
  req.headers["Authorization"] = `Bearer ${login_Data?.token}`;
  return req;
});

export { axiosInstance };
