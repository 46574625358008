import React, { useMemo, useState } from "react";
// import MyMenu from "../common/MyMenu1";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Modal,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import CommonTable1 from "../../common/CommanTable";
// import CommonAction from "../common/CommonAction";

import { useQuery } from "react-query";
import { getStaffList } from "../../services/auth";
import formatDate from "../../common/Formater";
import { useFormik } from "formik";
import { toast } from "react-toastify";

// main Components
const StaffList = () => {
  const [data, setData] = useState([]);
  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, index) => index + 1,
        id: "rowIndex", // This is a unique ID (required)
        Cell: ({ cell }) => {
          return (
            <div>
              {cell.row.index + 1 > 9
                ? cell.row.index + 1
                : `0${cell.row.index + 1}`}
            </div>
          );
        },
        size: 10,
      },
      {
        accessorKey: "firstName",
        header: "User Name",
        size: 100,
      },
      {
        accessorKey: "email",
        header: "Email Id",
        size: 100,
      },
      {
        accessorKey: "mobileNumber", //normal accessorKey
        header: "Mobile Number",
        size: 100,
      },
      {
        accessorKey: "designation",
        header: "Role",
        size: 100,
      },
      {
        accessorKey: "createdOn",
        header: "Account Created",
        size: 100,
        Cell: ({ cell }) => {
          let value = cell.getValue();
          return <div>{formatDate(value)}</div>;
        },
      },
      // {
      //     header: "Action",
      //     size: 100,
      //     Cell: ({ cell, index }) => (
      //         <CommonAction data={data} row={cell} idx={index} />
      //     ),
      // },
    ],
    [data]
  );

  // getting Staff List
  const { isLoading: staffListLoading } = useQuery({
    queryKey: "staffList",
    queryFn: () => getStaffList(),
    onSuccess: (staffList) => {
      setData(staffList.data.list);
      console.log("@staffList : getting Staff list", staffList.data.list);
    },
    onError: (error) => {
      console.log("@staffList : errror while getting Staff list", error);
    },
  });

  if (staffListLoading) {
    return <div>loading...</div>;
  }

  return (
    <>
      <StyledWrapper>
        <MyMenu
          name="Staff List"
          total={data?.length}
          setData={setData}
        ></MyMenu>

        <Box sx={{ borderRadius: "100px" }}>
          <CommonTable1
            data={data}
            columns={columns}
            muiTableBodyRowProps={{ hover: true }}
            style={{ borderRadius: "10px" }}
          ></CommonTable1>
        </Box>
      </StyledWrapper>
    </>
  );
};
export default StaffList;

// All other helping componnets used in this
const MyMenu = (props) => {
  const [showHide, setShowHide] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowHide(open);
  };
  return (
    <>
      <Stack width={"100%"} direction={"row"}>
        <Stack sx={{ justifyContentt: "flex-end" }} width={"50%"}>
          <Typography varient={"h4"} className="over-view-data-title">
            {props.name}
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          width={"50%"}
          sx={{ justifyContent: "flex-end", alignItems: "center" }}
        >
          {props.total && (
            <Typography
              varient={"h4"}
              className="over-view-data-title"
              sx={{ marginRight: "15px" }}
            >
              Total :{props.total}
            </Typography>
          )}
          {/* <Button
            variant="contained"
            className="filter-button"
            onClick={toggleDrawer(true)}
          >
            Apply Filter
          </Button> */}
          <Drawer
            anchor={"right"}
            open={showHide}
            onClose={toggleDrawer(false)}
          >
            <TableFilter
              close={() => setShowHide(false)}
              setData={props.setData}
            />
          </Drawer>

          {/* <Stack
            direction={"row"}
            sx={{ justifyContent: "start", alignItems: "center" }}
          >
            <Typography
              component={"h4"}
              className="export-label"
              sx={{ margin: "17px" }}
            >
              Export :-
            </Typography>
            <IconButton>
              <img src="/xcel.png" alt="xcel" />
            </IconButton>
            <IconButton>
              <img src="/doc.png" alt="xcel" />
            </IconButton>
            <IconButton>
              <img src="/pdf.png" alt="xcel" />
            </IconButton>
          </Stack> */}
        </Stack>
      </Stack>
    </>
  );
};

const TableFilter = ({ close, setData }) => {
  const [params, setParams] = useState({});

  // getting filtered data
  const { refetch } = useQuery({
    queryKey: "staffList_filter",
    queryFn: () => getStaffList(params),
    onSuccess: (staffList) => {
      setData(staffList.data.list);
      toast?.success(staffList?.data?.message);
    },
    onError: (error) => {
      toast?.error(error?.response?.data?.message);
    },
    enabled: false,
  });
  /// Using formik
  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      byName: false,
      byRoll: false,
      byMobile: false,
      byEmail: false,

      name: "",
      roll: "",
      mobile: "",
      email: "",
    },
    onSubmit: (formData) => {
      const body = {
        ...(formData?.byName ? { firstName: formData.name } : {}),
        ...(formData?.byRoll ? { designation: formData.roll } : {}),
        ...(formData?.byEmail ? { email: formData.email } : {}),
        ...(formData?.byMobile ? { mobileNumber: formData.mobile } : {}),
      };

      setParams(body);
      refetch();
    },
  });

  return (
    <Box width="300px">
      {/* Title Open here */}
      <Box sx={{ p: 1 }}>
        <Stack
          direction={"row"}
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Typography varient={"h4"}>User List</Typography>
          <IconButton sx={{ p: 0, margin: 0 }} onClick={close}>
            <img alt="close" src="/close_filter.png" />
          </IconButton>
        </Stack>
      </Box>
      {/* Title closed here */}
      <Divider />
      <Box sx={{ p: 2 }} component="form" onSubmit={handleSubmit}>
        <CommonInputSmall
          id="name"
          label="By Name"
          type="text"
          value={values.name}
          open={values.byName}
          name="name"
          handeler={handleChange}
        >
          <input
            type="checkbox"
            value={values.byName}
            name="byName"
            onChange={handleChange}
          />
        </CommonInputSmall>

        <CommonInputSmall
          id="roll"
          label="By Roll"
          type="text"
          value={values.roll}
          open={values.byRoll}
          name="roll"
          handeler={handleChange}
        >
          <input
            type="checkbox"
            value={values.byRoll}
            name="byRoll"
            onChange={handleChange}
          />
        </CommonInputSmall>

        <CommonInputSmall
          id="mobile"
          label="Mobile Number"
          type="text"
          name="mobile"
          value={values.mobile}
          open={values.byMobile}
          handeler={handleChange}
        >
          <input
            type="checkbox"
            value={values.byMobile}
            name="byMobile"
            onChange={handleChange}
          />
        </CommonInputSmall>

        <CommonInputSmall
          id="email"
          label="Email"
          type="email"
          name="email"
          value={values.email}
          open={values.byEmail}
          handeler={handleChange}
        >
          <input
            type="checkbox"
            value={values.byEmail}
            name="byEmail"
            onChange={handleChange}
          />
        </CommonInputSmall>

        <Stack>
          <Button
            type="submit"
            width={"100%"}
            sx={{
              p: 1,
              borderRadius: "10px",
              background: " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
              flexShrink: 0,
              marginTop: "30px",
            }}
            variant="contained"
            size="small"
          >
            Apply
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

const CommonInputSmall = (props) => {
  return (
    <>
      <StyledTable>
        <Box className="form-control">
          <label className="label" htmlFor={props.id}>
            {props?.children}
            {props.label}
          </label>
          <br />
          {props?.open && (
            <input
              name={props.name || props.label || ""}
              className="input"
              type={props.type}
              placeholder={props.label}
              value={props.value}
              disabled={props?.disabled || false}
              onChange={props?.handeler || props?.onChange}
            />
          )}
        </Box>
      </StyledTable>
    </>
  );
};

const StyledTable = styled(Box)`
  margin-top: 10px;
  text-align: left;
  .label {
    color: #020c0a;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    padding: 10px 0;
    margin: 10px 0;
    opacity: 0.75;
    outline: none;
  }
  .input {
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.1);
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(61, 57, 137, 0.05);
    letter-spacing: 0.4px;
    padding: 12px;
    margin-top: 10px;
    outline: none;
  }
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  //backgroundColor:"background.paper",
  boxShadow: 0,
  borderRadius: "10px",
  width: "463.638px",
  //height: "487.232px",
};

const CommonAction = (props) => {
  const { data, row } = props;
  let idx = parseInt(row.id);
  const staff = data[idx];

  const [open, setOpen] = useState({ view: false, edit: false, delete: false });

  const openModel = (name) => {
    if (name === "edit") {
      setOpen((prev) => ({ ...prev, edit: true }));
    } else if (name === "delete") {
      setOpen((prev) => ({ ...prev, delete: true }));
    } else {
      setOpen((prev) => ({ ...prev, view: true }));
    }
  };
  const closeMdedel = (name) => {
    if (name === "edit") {
      setOpen((prev) => ({ ...prev, edit: false }));
    } else if (name === "delete") {
      setOpen((prev) => ({ ...prev, delete: false }));
    } else {
      setOpen((prev) => ({ ...prev, view: false }));
    }
  };

  ///// form handelling
  const [value, setValue] = useState("Staff");

  //  const {refetch} = useQuery({
  //    queryKey:"upadating desination",
  //    queryFn:()=> updateStaffDesination({designation:"Staff",userDocId:"6530c32a6364a60673110947"}),
  //    onSuccess:()=>{
  //       console.log("upddated")
  //    },
  //    onError:()=>{
  //       console.log("error")
  //    },
  //    enabled:false
  //  })

  //   const {mutate} = useMutation(updateStaffDesination({designation:"Staff",userDocId:"6530c32a6364a60673110947"}),{
  //     onSuccess:(data)=>{
  //        console.log(data);
  //     },
  //     onError:(e)=>{
  //        console.log(e);
  //    },
  //  })

  return (
    <Stack direction="row" spacing={0.5} sx={{ m: 0 }}>
      <IconButton sx={{ p: 0 }} onClick={() => openModel("view")}>
        <img src="./action_view.png" alt="view" />
      </IconButton>
      <IconButton sx={{ p: 0 }} onClick={() => openModel("edit")}>
        <img src="./action_edit.png" alt="edit" />
      </IconButton>
      <IconButton sx={{ p: 0 }} onClick={() => openModel("delete")}>
        <img src="./action_delete.png" alt="delete" />
      </IconButton>
      {/* View Model*/}
      <Modal
        open={open.view}
        onClose={() => closeMdedel("view")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              px: 2,
              py: 1,
            }}
          >
            <Typography varient={"h4"} sx={{ textTransform: "capitalize" }}>
              Change Designation
            </Typography>
            <IconButton
              sx={{ p: 0, margin: 0 }}
              onClick={() => closeMdedel("view")}
            >
              <img alt="close" src="/close_filter.png" />
            </IconButton>
          </Stack>
          <Divider />
          {/* edit from */}
          <Stack justifyContent={"center"} alignItems={"center"} p="3">
            <h2 style={{ color: "red" }}>API Not Dsigen</h2>
          </Stack>
        </Box>
      </Modal>
      {/* edit model */}
      <Modal
        open={open.edit}
        onClose={() => closeMdedel("edit")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              px: 2,
              py: 1,
            }}
          >
            <Typography varient={"h4"} sx={{ textTransform: "capitalize" }}>
              Change Designation
            </Typography>
            <IconButton
              sx={{ p: 0, margin: 0 }}
              onClick={() => closeMdedel("edit")}
            >
              <img alt="close" src="/close_filter.png" />
            </IconButton>
          </Stack>
          <Divider />
          {/* edit from */}
          <Box sx={{ p: 2 }}>
            <Stack direction="row" spacing={2}>
              <CommonInputSmall
                label="Current Desination"
                value={staff?.designation}
                disabled={true}
              />
              <CommonInputSmall
                value={value}
                onChange={(e) => setValue(e.target.value)}
                label="New Destination"
              />
            </Stack>
            <Stack direction="row" sx={{ justifyContent: "flex-end" }}>
              <Button
                className="confirm-button"
                type="submit"
                sx={{
                  width: "95px",
                  p: 1,
                  borderRadius: "10px",
                  background:
                    " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
                  flexShrink: 0,
                }}
                variant="contained"
                size="small"
                // onClick={()=> mutate()}
              >
                Update
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>

      {/* delte model */}
      <Modal
        open={open.delete}
        onClose={() => setOpen((prev) => ({ ...prev, delete: false }))}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 300, p: 3, textAlign: "center" }}>
          <Stack sx={{ justifyContent: "center", alignItem: "center", p: 3 }}>
            <Box>
              <img width="69px" src="./delete-icon.png" alt="delete" />
            </Box>
          </Stack>
          <Typography
            variant="h6"
            className="confirm-title"
            sx={{
              color: "#020c0a",
              textAlign: "center",
              fontSize: "22px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
            }}
          >
            Are You Sure ?
          </Typography>
          <Typography
            varient="body1"
            className="confirm-description"
            sx={{
              color: "#020C0A",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              marginTop: "15px",
              opacity: "0.5",
            }}
          >
            You want to delete this Event
          </Typography>
          <h2 style={{ color: "red" }}>API Not Working.</h2>
          <Stack
            direction="row"
            justifyContent={"center"}
            sx={{ marginTop: "30px" }}
            spacing={2}
          >
            <Button
              className="confirm-button"
              type="submit"
              sx={{
                width: "95px",
                height: "50px",
                p: 1,
                marginTop: "30px",
                borderRadius: "10px",
                flexShrink: 0,
              }}
              variant="outlined"
              size="small"
            >
              No
            </Button>
            <Button
              className="confirm-button"
              type="submit"
              sx={{
                width: "95px",
                height: "50px",
                p: 1,
                marginTop: "30px",
                borderRadius: "10px",
                background:
                  " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
                flexShrink: 0,
              }}
              variant="contained"
              size="small"
            >
              Yes
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Stack>
  );
};

const StyledWrapper = styled(Box)`
  padding: 20px 30px;
  box-sizing: border-box;
  max-width: 100%;
  table {
    border-radius: 10px;
    overflow: hidden;
  }
  .over-view-data-title {
    color: #020c0a;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 20px 0;
  }
  .filter-button {
    width: 122.823px;
    height: 36.062px;
    flex-shrink: 0;
    color: #fff;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-size: 12px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    border-radius: 10px;
    background: #1a5346;
    text-transform: capitalize;
  }
  .export-label {
    color: #020c0a;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
  }
`;
