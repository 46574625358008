import { useFormik } from "formik";
import { Button, Divider, IconButton, Typography , Box, Stack,} from "@mui/material";

import styled from "styled-components";

const TableFilterUpload = ({ close,setData}) => {

    const { values, handleChange, handleSubmit } = useFormik({
      initialValues: {
        byName: false,
        byAge: false,
        byMobile: false,
        byEmail: false,
        byState: false,
        byDist: false,
        bySub: false,
        name: "",
        age: {
          from: "",
          to: "",
        },
        mobile: "",
        email: "",
        state: "",
        dist: "",
        subscriptiontype: "",
      },
      onSubmit: async (formData) => {
        const body = {
          ...(formData?.byName ? { firstName: formData.name } : {}),
          ...(formData?.byAge ? { from: formData.age.from, to: formData.age.to } : {}),
          ...(formData?.byState ? { state: formData.state } : {}),
          ...(formData?.byDist ? { dist: formData.dist } : {}),
          ...(formData?.byEmail ? { email: formData.email } : {}),
          ...(formData?.byMobile ? { mobileNumber: formData.mobile } : {}),
        };
        alert("api not desingen")
        console.log("form data",formData)
        console.log(body);
     
      },
    });
  
    return (
      <Box width="300px">
        <Box sx={{ p: 1 }}>
          <Stack
            direction={"row"}
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Typography varient={"h4"}>Content Filter</Typography>
            <IconButton sx={{ p: 0, margin: 0 }} onClick={close}>
              <img alt="close" src="/close_filter.png" />
            </IconButton>
          </Stack>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }} component="form" onSubmit={handleSubmit}>
          <CommonInputSmall
            id="name"
            label="By Name"
            type="text"
            value={values.name}
            name="name"
            handeler={handleChange}
          >
            <input
              type="checkbox"
              value={values.byName}
              name="byName"
              onChange={handleChange}
            />
          </CommonInputSmall>
  
          <Box sx={{ margin: "10px 0" }}>
            <Typography className="label">
              <input
                type="checkbox"
                value={values.byAge}
                name="byAge"
                onChange={handleChange}
              />
              Age Group
            </Typography>
            <Stack
              spacing={2}
              direction="row"
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <CommonInputSmall
                id="start_num"
                label="From"
                type="number"
                name="age.from"
                value={values.age.from}
                handeler={handleChange}
              />
              <CommonInputSmall
                id="end_num"
                label="To"
                type="number"
                name="age.to"
                value={values.age.to}
                handeler={handleChange}
              />
            </Stack>
          </Box>
  
          <CommonInputSmall
            id="mobile"
            label="Mobile Number"
            type="text"
            name="mobile"
            value={values.mobile}
            handeler={handleChange}
          >
            <input
              type="checkbox"
              value={values.byMobile}
              name="byMobile"
              onChange={handleChange}
            />
          </CommonInputSmall>
  
          <CommonInputSmall
            id="email"
            label="Email"
            type="email"
            name="email"
            value={values.email}
            handeler={handleChange}
          >
            <input
              type="checkbox"
              value={values.byEmail}
              name="byEmail"
              onChange={handleChange}
            />
          </CommonInputSmall>
  
          <CommonInputSmall
            id="state"
            label="State"
            type="text"
            name="state"
            value={values.state}
            handeler={handleChange}
          >
            <input
              type="checkbox"
              value={values.byState}
              name="byState"
              onChange={handleChange}
            />
          </CommonInputSmall>
  
          <CommonInputSmall
            id="district"
            label="District"
            type="text"
            name="dist"
            value={values.dist}
            handeler={handleChange}
          >
            <input
              type="checkbox"
              value={values.byDist}
              name="byDist"
              onChange={handleChange}
            />
          </CommonInputSmall>
  
          <CommonInputSmall
            id="subscription"
            label="Subscription Type"
            type="text"
            name="subscriptiontype"
            value={values.subscriptiontype}
            handeler={handleChange}
          >
            <input
              type="checkbox"
              value={values.bySub}
              name="bySub"
              onChange={handleChange}
            />
          </CommonInputSmall>
  
          <Stack>
            <Button
              type="submit"
              width={"100%"}
              sx={{
                p: 1,
                borderRadius: "10px",
                background: " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
                flexShrink: 0,
                marginTop: "30px",
              }}
              variant="contained"
              size="small"
            >
              Apply
            </Button>
          </Stack>
        </Box>
      </Box>
    );
  };
  
export default TableFilterUpload;

  const CommonInputSmall = (props) => {
    return (
      <>
        <StyledTable>
          <Box className="form-control">
            <label className="label" htmlFor={props.id}>
              {props?.children}
              {props.label}
            </label>
            <br />
  
            <input
              name={props.name || props.label || ""}
              className="input"
              type={props.type}
              placeholder={props.label}
              value={props.value}
              onChange={props.handeler}
            />
          </Box>
        </StyledTable>
      </>
    );
  };
  
  const StyledTable = styled(Box)`
    margin-top: 10px;
    text-align: left;
    .label {
      color: #020c0a;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.32px;
      padding: 10px 0;
      margin: 10px 0;
      opacity: 0.75;
      outline: none;
    }
    .input {
      width: 100%;
      box-sizing: border-box;
      border-radius: 10px;
      border: 1px solid rgba(2, 12, 10, 0.1);
      background: #fff;
      box-shadow: 0px 4px 10px 0px rgba(61, 57, 137, 0.05);
      letter-spacing: 0.4px;
      padding: 12px;
      margin-top: 10px;
      outline: none;
    }
  `;