import * as Yup from "yup";

export const signInSchema = Yup.object({
//   name: Yup.string().min(2).max(25).required("Please enter your name"),
  email: Yup.string().email().required("Please enter your email"),
  password: Yup.string().min(6).required("Please enter your password"),
//   confirm_password: Yup.string()
//     .required()
//     .oneOf([Yup.ref("password"), null], "Password must match"),
});
export const signUpSchema = Yup.object({
  name: Yup.string().min(2).max(25).required("Please enter your name"),
  email: Yup.string().email().required("Please enter your email"),
  mobile: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits').required('Please enter your mobile phone number'),
  role: Yup.string().notOneOf(['select',""], 'Please select a role').required('Please select a role'), 
  
//   confirm_password: Yup.string()
//     .required()
//     .oneOf([Yup.ref("password"), null], "Password must match"),
});