import React from 'react';
import ReusableForm from './ReusableForm'; // Adjust the import path
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const initialValues = {
    name: '',
    email: '',
};

const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
});

const submitFunction = async (formData) => {
    // Simulating API call
    const response = await fetch('https://api.example.com/submit', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

const MyPage = () => {
    return (
        <div>
            <h1>My Form</h1>
            <ReusableForm
                initialValues={initialValues}
                validationSchema={validationSchema}
                submitFunction={submitFunction}
            >
                <div>
                    <label htmlFor="name">Name</label>
                    <Field type="text" id="name" name="name" />
                    <ErrorMessage name="name" component="div" />
                </div>
                <div>
                    <label htmlFor="email">Email</label>
                    <Field type="email" id="email" name="email" />
                    <ErrorMessage name="email" component="div" />
                </div>
            </ReusableForm>
        </div>
    );
};

export default MyPage;
