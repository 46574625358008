import { Box, styled, Stack, Paper, Typography, Button, } from "@mui/material";
import React, { useState } from "react";
import formatDate from "../../common/Formater";

const Calendar = () => {
  // alert("APIs Not Completed For Get Photo by Date by Event...");

  const [event ,setEvent] = useState([
    {name:"Ghandhi Jayanti",photos:0},
    {name:"Basant Panc",photos:0},
    {name:"Jayanti Festival",photos:0},
 ])
 const [active, setActive] = useState("0");
const date = formatDate(new Date());
   
  return (
    <StyledWrapper>
      <Stack
        direction={{ sm: "row", ms: "column" }}
        sx={{ padding: "10px 0", heigth: "72.099px", alignItems: "center" }}
      >
        <Stack
          width={{ sm: "100%", ms: "50%" }}
          direction={{ sm: "row", ms: "column" }}
          sx={{ justifyContent: "flex-start", alignItems: "center" }}
          spacing={2}
        >
          {event.map((e,i)=>(
              <Button
              key={i}
              sx={{ m: 0, p: 0, textAlign:"left"}}
              onClick={() => {
                setActive(i.toString()); // Update the active state to the index as a string
              }}
              className={i.toString() === active ? "active" : ""}
            >
              <Paper square className="over-view">
                <Typography variant="body2" className="festival_name">
                  {e.name}
                </Typography>
                <Typography component={"h4"} className="photo_count">
                  {e.photos} Photos
                </Typography>
              </Paper>
            </Button>
          ))}
        
        </Stack>
        <Stack
          width={{ sm: "100%", ms: "50%" }}
          sx={{ alignItems: "flex-end" }}
        >
          <Button sx={{textTransform:"capitalize",color:"black"}}> <h2 style={{color:"red"}}>API Not Made For Get Photo By Day By Event.</h2>  <Typography>Event Date : {date} </Typography></Button>
        </Stack>
      </Stack>
      <Box sx={{ marginTop: "10px" }}>
        <Stack
          spacing={2}
          direction={"row"}
          justifyContent="space-between"
          sx={{
            p: 2,
            flexWrap: "wrap",
            padding: "0",
            margin: 0,
          }}
          useFlexGap
        >
          <Paper square className="event"></Paper>
          <Paper square className="event"></Paper>
          <Paper square className="event"></Paper>
          <Paper square className="event"></Paper>
          <Paper square className="event"></Paper>
          <Paper square className="event"></Paper>
          <Paper square className="event"></Paper>
          <Paper square className="event"></Paper>
          <Paper square className="event"></Paper>
          <Paper square className="event"></Paper>
          <Paper square className="event"></Paper>
          <Paper square className="event"></Paper>
          <Paper square className="event"></Paper>
          <Paper square className="event"></Paper>
          <Paper square className="event"></Paper>
        </Stack>
      </Box>
    </StyledWrapper>
  );
};

export default Calendar;
const StyledWrapper = styled(Box)`
  padding: 20px 30px;
  box-sizing: border-box;
  max-width: 100%;

  .over-view {
    padding: 19.6px;
    width: 150px;
    // height: 72.099px;
    flex-shrink: 0;
    text-decoration: none;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.3);
    background: #fff;
  }

  .active .over-view {
    padding: 19.6px;
    width: 150px;
    // height: 72.099px;
    flex-shrink: 0;
    text-decoration: none;
    border-radius: 10px;
    border: 1px solid #5cd6b9;
    background: #5cd6b9;
  }
  .active .over-view .festival_name,
  .active .over-view .photo_count {
    color: #fff;
  }
  .festival_name {
    text-decoration: none;
    color: #020c0a;
    leading-trim: both;
    text-edge: cap;
    //font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .photo_count {
    color: #020c0a;
    leading-trim: both;
    text-edge: cap;
    opacity: 0.5;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .event {
    width: 208.927px;
    //width: 'calc((100% - 128px) / 4)' ;
    height: 186.395px;
    flex-shrink: 0;
    border-radius: 10px;
    opacity: 0.1;
    background: #1a5346;
    //margin-bottom:15px;
    margin-left: 0;
    //margin-right:15px;
  }
  a {
    text-decoration: none;
  }
`;
