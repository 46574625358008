import {
  Button,
  Divider,
  IconButton,
  Modal,
  Stack,
  Typography,
  Box,
} from "@mui/material";

import React, { useMemo, useState } from "react";
import styled from "styled-components";
import CommonTable1 from "../../common/CommanTable";
import { addPhoto, getPhoto } from "../../services/auth";
import { useMutation, useQuery } from "react-query";
import formatDate from "../../common/Formater";
import CommonActionPhoto from "./CommonActionPhoto";
import PhotoForm from "./forms/PhotoFrom";
// Style for PopUp Model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
  boxShadow: 24,
  borderRadius: "10px",
  width: "700.638px",
};

const PhotoTable = () => {
  // Apply Filter
  const [showHide, setShowHide] = useState(false);
  // const toggleDrawer = (open) => (event) => {
  //   if (
  //     event.type === "keydown" &&
  //     (event.key === "Tab" || event.key === "Shift")
  //   ) {
  //     return;
  //   }
  //   setShowHide(open);
  // };
  // That is not completed yet , not Api niether figma
  //Model Opening States Handeling
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // For refreshing the data
  let refresh;
  let close;

  // Form Getting States Handeling
  const [data, setData] = useState([]);
  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, index) => index + 1,
        id: "rowIndex", // This is a unique ID (required)
        Cell: ({ cell }) => {
          return (
            <div>
              {cell.row.index + 1 > 9
                ? cell.row.index + 1
                : `0${cell.row.index + 1}`}
            </div>
          );
        },
        size: 10,
      },
      {
        accessorKey: "name",
        header: "Event Name",
        size: 100,
        // Cell: ({ cell }) => {
        //   let value = cell.getValue();
        //   if(!value ) return "----";
        //   return value;
        // },
      },
      {
        accessorKey: "createdOn", //normal accessorKey
        header: "Created On",
        size: 100,
        Cell: ({ cell }) => {
          let value = cell.getValue();
          return <div>{formatDate(value)}</div>;
        },
      },
      {
        accessorKey: "date",
        header: "Date",
        size: 100,
        Cell: ({ cell }) => {
          let value = cell.getValue();
          if (!value) return "----";
          return <div>{formatDate(value)}</div>;
        },
      },
      {
        accessorKey: "categoryTag",
        header: "Category Tag",
        size: 150,
        Cell: ({ cell }) => {
          let value = cell.getValue();
          if (!value) return "----";
          return value;
        },
      },
      {
        accessorKey: "tags",
        header: "Tags",
        size: 150,
        Cell: ({ cell, index }) => (
          <>
            {cell?.row?.original?.tags.map((tag, i) => (
              <span
                style={{
                  color: "white",
                  backgroundColor: "black",
                  borderRadius: "4px",
                  marginLeft: "3px",
                  padding: "4px",
                }}
                key={i}
              >
                {tag}{" "}
              </span>
            ))}
          </>
        ),
      },
      {
        header: "Action",
        size: 100,
        Cell: ({ cell, index }) => (
          <CommonActionPhoto
            refresh={refresh}
            event={cell?.row?.original}
            close={close}
          />
        ),
      },
    ],
    []
  );
  const { refetch } = useQuery({
    queryKey: "events",
    queryFn: () => getPhoto(),
    onSuccess: (events) => {
      setData(events.data.list);
      //console.log("@PhotoTable :getting data of photos", events.data.list);
    },
    onError: (error) => {
      console.log("@PhotTable : error getting photos ", error);
    },
  });
  refresh = refetch;

  const initialValues = {
    withDate: true,
    photo:
      "https://ekalsutra.s3.ap-south-1.amazonaws.com/public/photo/65ce263c661237378c797b03/logo1.png",
    language: "hindi",
    tags: ["holi", "diwali"],
    name: "",
    date: formatDate(new Date()),
    eventName: "",
    //"categoryTag == quotes": "without Date",
    categoryTag: "quotes",
    categoryName: "politics",
    categoryDocId: "65eaf4e9353d5cb6f43823ce",
    subCategoryName: "politics",
    subCategoryDocId: "65eaf5883573561e46348173",
    quotesName: "Morning",
    trendName: "trend",
    category: "politics", //quotes,trending,politics,business
    subCategory: "Testing",
    partyName: "BJP",
    partyDocId: "65cb0706feacf71abcc60267",
    leaderName: "Narendra Modi",
    businessName: "Narendra Modi",
    state: "U.P.",
    leaderName: "Testing",
  };

  close = handleClose;
  return (
    <StyledWrapper>
      {/*Main Menu*/}
      <Stack
        direction="row"
        sx={{ justifyContent: "flex-end", padding: "20px 0" }}
        spacing={2}
      >
        <Button
          variant="contained"
          className="button-add-frame"
          onClick={handleOpen}
        >
          + Add Photo
        </Button>
      </Stack>
      {/*Data Table*/}
      <CommonTable1 data={data} columns={columns}/>
      {/* Model Box */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              px: 2,
              py: 1,
            }}
          >
            <Typography varient={"h4"} sx={{ textTransform: "capitalize" }}>
              Add Photo
            </Typography>
            <IconButton sx={{ p: 0, margin: 0 }} onClick={handleClose}>
              <img alt="close" src="/close_filter.png" />
            </IconButton>
          </Stack>
          <Divider />
          {/* Photo Form is Here */}
          <PhotoForm
            initialValues={initialValues}
            refresh={refetch}
            close={handleClose}
          />
        </Box>
      </Modal>
    </StyledWrapper>
  );
};

export default PhotoTable;

const StyledWrapper = styled(Box)`
  .over-view {
    width: 100.35px;
    border-radius: 10px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.3);
    background: #fff;
  }
  .doc-type {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: capitalize;
  }
  .active .over-view {
    background-color: #5bd5b9;
  }
  .active .doc-type {
    color: #fff;
  }
  a {
    text-decoration: none;
  }
  .button-filter {
    width: 122.823px;
    height: 36.062px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #1a5346;
    text-transform: capitalize;
  }
  .button-add-frame {
    text-transform: capitalize;
    width: 173.371px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.05);
    background: #1a5346;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  .model-upload-button {
    width: 123.091px;
    height: 122.035px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid rgba(21, 70, 58, 0.2);
    background: rgba(90, 212, 183, 0.1);
  }
`;
