export const PATH_CONSTANTS = {
  login: "/login",
  register: "/register",
  dashboard: "/",
  userList: "/user-list",
  staffList: "/staff-list",
  calendar: "/calendar",
  upload : "/upload",
  event: "/event",
};
export const COLORS_CONSTANTS = {
  primry: "",
  secondary: "",
  sidebar_gradient:
    "background: linear-gradient(198deg, #6BE1C5 -17.44%, #31B696 103.72%)",
};
export const SIZES_CONSTANTS = {
  navbarHeight: "70px",
  sidebar_width: "250px",
};
