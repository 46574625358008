import { useState } from "react";
import {
  styled,
  Box,
  Stack,
  Typography,
  IconButton,
  Modal,
  Divider,
  Avatar,
  Button,
} from "@mui/material";
import { SIZES_CONSTANTS } from "../utils/constants";
import CommonInputSmall from "../common/CommonInputSmall";
import { useMutation, useQuery } from "react-query";
import { getUserProfile, updateSelfProfile } from "../services/auth";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { setUserDetails } from "../services/redux/authSlice";

//style for model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 0,
  borderRadius: "10px",
  width: "463.638px",
};

const Navbar = () => {
  const dispatch = useDispatch();

  // regetng (Updating) getting User Details
  const { refetch: refetchUserDetail } = useQuery({
    queryKey: ["getUserProfile"],
    queryFn: () => getUserProfile(),
    onSuccess: (user) => {
      console.log("@dashboard(Navbar): updating the user Data", user.data.obj);
      dispatch(setUserDetails(user.data.obj));
    },
    enabled: false,
  });

  const userDetail = useSelector((store) => store.auth.userDetail); // geting data fromlogin credetial
  const [open, setOpen] = useState(!userDetail.isProfileUpdated);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    if (userDetail.isProfileUpdated) {
      // risctricting to close whothout adding information
      setOpen(false);
    } else {
      alert("Please Enter the the Details to continue....");
    }
  };

  return (
    <StyledWrapper>
      <Stack
        className="navBar"
        spacing="2"
        direction={"row"}
        sx={{ fontSize: "100%" }}
      >
        <img
          src="/notification1.png"
          alt="profile_pic"
          className="notification"
        />
        <img
          src={userDetail?.profilePic ? userDetail?.profilePic : "/user.webp"}
          alt="profile_pic"
          className="profile_pic"
        />
        <Typography
          className="profileName"
          variant="h6"
          component="h4"
          sx={{ fontSize: "12.5px", cursor: "pointer" }}
          onClick={handleOpen}
        >
          {userDetail?.firstName}
          <img src="/Stroke 1.png" alt="stroke" className="stroke" />
        </Typography>
      </Stack>

      {/* modal for profile pic showing */}
      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="profile-modal-title"
          aria-describedby="profile-modal-description"
          sx={{
            justifyContent: "center",
            height: "100vh",
            alignItems: "center",
          }}
        >
          <Box sx={{ ...style }}>
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                p: 1,
                px: 2,
              }}
            >
              <Typography varient={"h4"}>Profile</Typography>
              <IconButton sx={{ p: 0, margin: 0 }} onClick={handleClose}>
                <img alt="close" src="/close_filter.png" />
              </IconButton>
            </Stack>
            <Divider />

            <Stack
              sx={{ justifyContent: "center", alignItems: "center", p: 2 }}
            >
              <Avatar
                className="avatar"
                alt="Remy Sharp"
                src={userDetail?.profilePic}
                sx={{ width: 106, height: 106 }}
              />
            </Stack>
            <PfrofileView
              style={style}
              refetchUserDetail={refetchUserDetail}
              close={setOpen}
            />
          </Box>
        </Modal>
      </Box>
    </StyledWrapper>
  );
};

const PfrofileView = ({ close, refetchUserDetail }) => {
  const userDetail = useSelector((store) => store.auth.userDetail);

  const mode = userDetail.isProfileUpdated ? "view" : "edit";

  const [edit, setEdit] = useState(mode);

  const { mutate: UpdateProfile } = useMutation(updateSelfProfile, {
    onSuccess: (data) => {
      console.log("profile update seccuessful");
      refetchUserDetail();
    },
    onError: (error) => {
      console.log("profile Update error");
    },
  });

  const initialValues = {
    mobileNumber: userDetail.mobileNumber,
    profilePic: "www.insta.com",
    firstName: userDetail.firstName ? userDetail.firstName : "",
    middleName: "",
    lastName: "",
    subscriptionType: "Basic",
    DOB: "2000-05-10",
    gender: "female",
    email: userDetail.email ? userDetail.email : "",
    maritalStatus: "unmarried",
    fatherInfo: {
      firstName: "Ram",
    },
    motherInfo: {
      firstName: "sita",
    },
    isProfileUpdated: true,
    currentAddress: {
      address: "Gkp",
      dist: "yu",
      state: "up",
      pinCode: "209768",
    },
  };
  const { values, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit: (formData, action) => {
      console.log(
        "🚀 ~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
        values
      );

      UpdateProfile(formData);

      //action.resetForm();
    },
  });
  return (
    <StyledWrapper1>
      {edit === "view" ? (
        <>
          <Box className="box-profile">
            <Typography className="title">Name</Typography>
            <Typography className="value">{userDetail?.firstName}</Typography>
          </Box>
          <Box className="box-profile">
            <Typography className="title">Roll</Typography>
            <Typography className="value">{userDetail?.designation}</Typography>
          </Box>
          <Box className="box-profile">
            <Typography className="title">Mobile</Typography>
            <Typography className="value">
              {userDetail?.mobileNumber}
            </Typography>
          </Box>
          <Box className="box-profile">
            <Typography className="title">Email</Typography>
            <Typography className="value">{userDetail.email}</Typography>
          </Box>
          <Stack>
            {!userDetail?.isProfileUpdated ? (
              <Button
                type="submit"
                width={"100%"}
                sx={{
                  p: 1,
                  borderRadius: "10px",
                  background:
                    " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
                  flexShrink: 0,
                  marginTop: "30px",
                }}
                variant="contained"
                size="small"
                onClick={() => setEdit("edit")}
              >
                Edit Profile
              </Button>
            ) : (
              <Typography
                component={"h4"}
                varient={"body2"}
                color={"gereen"}
                sx={{ fontWeight: "bold", p: 2 }}
              >
                Please refer to app for changing the profile pic
              </Typography>
            )}
          </Stack>
        </>
      ) : (
        <>
          <form id="userUpdate">
            <CommonInputSmall
              label="Name"
              value={values?.firstName}
              name="firstName"
              handeler={handleChange}
            />
            <CommonInputSmall
              label="Roll"
              value={userDetail?.designation}
              disabled={true}
            />
            <CommonInputSmall
              label="Mobile"
              value={values.mobileNumber}
              name="mobileNumber"
              type="tel"
              handeler={handleChange}
            />
            <CommonInputSmall
              label="Email"
              value={values.email}
              name="email"
              type="email"
              handeler={handleChange}
            />
            <ChildModal handelYes={close} submit={handleSubmit} />
          </form>
        </>
      )}
    </StyledWrapper1>
  );
};

export default Navbar;

function ChildModal({ handelYes, submit }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const submitFunction = () => {
    submit();
    handleClose();
    handelYes(false);
  };
  return (
    <>
      <Stack>
        <Button
          sx={{
            marginTop: "30px",
            borderRadius: "10px",
            background: " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
            flexShrink: 0,
            padding: "10px",
          }}
          variant="contained"
          size="small"
          onClick={handleOpen}
        >
          Save
        </Button>
      </Stack>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 300, p: 3, textAlign: "center" }}>
          {/* <Stack sx={{justifyContent:"center",alignItem:"center", p:3}}>
             <Box>
               <img width="69px" src="./delete-icon.png" alt="delete"/>
             </Box>
          </Stack> */}
          <Typography
            variant="h6"
            className="confirm-title"
            sx={{
              color: "#020c0a",
              textAlign: "center",
              fontSize: "22px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
            }}
          >
            Are You Sure ?
          </Typography>
          <Typography
            varient="body1"
            className="confirm-description"
            sx={{
              color: "#020C0A",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              marginTop: "15px",
              opacity: "0.5",
            }}
          >
            You want to change this details
          </Typography>
          <Stack
            direction="row"
            justifyContent={"center"}
            sx={{ marginTop: "30px" }}
            spacing={2}
          >
            <Button
              className="confirm-button"
              sx={{
                width: "95px",
                height: "50px",
                p: 1,
                marginTop: "30px",
                borderRadius: "10px",
                flexShrink: 0,
              }}
              variant="outlined"
              size="small"
              onClick={() => {
                handleClose();
              }}
            >
              No
            </Button>
            <Button
              className="confirm-button"
              // type="submit"
              // form='userUpdate'
              sx={{
                width: "95px",
                height: "50px",
                p: 1,
                marginTop: "30px",
                borderRadius: "10px",
                background:
                  " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
                flexShrink: 0,
              }}
              variant="contained"
              size="small"
              onClick={submitFunction}
            >
              Yes
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

const StyledWrapper1 = styled(Box)`
  padding: 20px 30px;
  .box-profile {
    margin-top: 15px;
    width: 410.541px;
    //height: 54px;
    flex-shrink: 0;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.1);
    background: rgba(93, 215, 186, 0.05);
    backgroundcolor: red;
    padding: 10px;
  }
  .title {
    color: #020c0a;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    opacity: 0.5;
  }
  .value {
    color: #020c0a;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

const StyledWrapper = styled(Box)`
  height: ${SIZES_CONSTANTS.navbarHeight};
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;

  .navBar {
    width: calc(100% - 250px);
    justify-content: flex-end;
    align-items: center;
  }
  .notification {
    width: 24px;
    height: 24px;
    text-align: center;
    object-fit: fill;
    border-radius: 38.725px;
    justify-content: center;
  }
  .profileName {
    color: #020c0a;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.54px;
    margin-left: 7px;
  }
  .profile_pic {
    width: 38.725px;
    height: 38.725px;
    text-align: center;
    object-fit: fill;
    border-radius: 38.725px;
    justify-content: center;
    margin-left: 16px;
  }
  .stroke {
    width: 15.771px;
    height: 8.648px;
    text-align: center;
    object-fit: fill;
    border-radius: 38.725px;
    justify-content: center;
    margin: 0 20px 2px 5px;
  }
  .avatar {
    width: 106.587px;
    height: 106.587px;
    flex-shrink: 0;
  }
`;
