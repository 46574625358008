import { Box, Button, Stack, styled, Typography } from "@mui/material";
import logo from "./img/logo.png";
import background from "./img/background.png";
import { Link, useNavigate } from "react-router-dom";
import { CustomReactInput } from "../../common/CommonReactInputs";

import { useDispatch } from "react-redux";
//validation importings
import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import {
  getUserProfile,
  login as hnadleLogin,
  SignInWithOTP,
} from "../../services/auth";
import * as yup from "yup";
import { useState } from "react";
import { setLoginState, setUserDetails } from "../../services/redux/authSlice";
import { setIsAuthenticated } from "../../services/redux/authSlice";
import { CustomTextField } from "../../assets/styles/globalStyledComponent";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [otp, setOTP] = useState(false); // showing OTP text fiels after sendingotpsuccessfully
  const [otpError, setOtpError] = useState(false); // for showing otp error

  // setting User details by getting it
  const { refetch: setUserProfile } = useQuery({
    queryKey: ["getUserProfile"],
    queryFn: () => getUserProfile(),
    onSuccess: (user) => {
      //console.log("@Login : getting the user Data",user.data.obj);
      dispatch(setUserDetails(user.data.obj));
      dispatch(setIsAuthenticated(true));
      navigate("/");
    },
    enabled: false,
  });

  // Sending OTP
  const { mutate: sendOTP, isLoading: sendOTPLoading } = useMutation(
    hnadleLogin,
    {
      onSuccess: (data) => {
        toast.success(data?.data?.message);
        setOTP(true);
      },
      onError: (e) => {
        toast?.error(e?.response?.data?.message);
      },
    }
  );

  // Verifyning OTP
  const { mutate: VerifyOTP, isLoading: verifyOTPLoading } = useMutation(
    SignInWithOTP,
    {
      onSuccess: (data) => {
        toast.success(data?.data?.message);
        dispatch(setLoginState(data.data));
        setUserProfile();
        setOtpError(false);
      },
      onError: (e) => {
        toast?.error(e?.response?.data?.message);
        setOtpError(true);
      },
    }
  );

  //Form Validation  Before Sending
  const validationSchema = yup.object().shape({
    mobile: yup
      .string()
      .required("Mobile number is required")
      .matches(
        /^[0-9]{10}$/,
        "Mobile number is not valid. It should be 10 digits"
      ),
  });
  // Formik
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues: { mobile: "", request_id: "abcd", OTP: "" },
    validationSchema,
    onSubmit: (values, action) => {
      let body;
      if (!otp) {
        body = { mobileNumber: values.mobile.toString() };
        sendOTP(body);
      } else {
        body = {
          mobileNumber: values.mobile.toString(),
          request_id: values.request_id,
          OTP: values.OTP.toString(),
        };
        VerifyOTP(body);
      }
      console.log("@login : onsubmit data", body);
    },
  });

  return (
    <StyleWrapper>
      <Stack width={"100%"} direction={"row"} sx={{ height: "100vh" }}>
        {/* Background or Logo Section start*/}
        <Stack
          sx={{
            width: "50%",
            backgroundColor: "#6FFFFF",
            backgroundImage: `url(${background})`, // Use `url()` to set the background image
            backgroundSize: "cover", // Optional: Set background size
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "264px",
              height: "264px",
              borderRadius: "100%",
              backgroundColor: "#020C0A1A",
            }}
          >
            <Stack
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "234px",
                height: "234px",
                backgroundColor: "#FFFFFF",
                borderRadius: "100%",
              }}
            >
              <Box sx={{ p: 3 }}>
                <img src={logo} alt="logo" />
              </Box>
            </Stack>
          </Stack>
        </Stack>
        {/* Background or Logo Section end*/}

        {/*Form stack */}
        <Stack
          width={"50%"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ padding: "50px" }}
        >
          {/*Form Box */}
          <Box
            component={"form"}
            onSubmit={handleSubmit}
            sx={{ padding: "0 50px" }}
          >
            <Typography className="title" variant="h4">
              LOGIN ACCOUNT
            </Typography>
            <Typography className="sub-title">
              Enter the Mobile Number to login
            </Typography>
            <Stack>
              <CustomTextField
                size="small"
                fullWidth
                value={values.mobile}
                onBlur={handleBlur}
                name="mobile"
                placeholder="XXXXXXXXXX"
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    handleChange(e);
                  }
                }}
              />
              {touched.mobile && errors.mobile && (
                <Typography sx={{ color: "red" }}>{errors.mobile}</Typography>
              )}
              {otp && (
                <Stack direction="row">
                  {/* <CustomReactInput
                    name="OTP"
                    id="OTP"
                    type="number"
                    values={values.OTP}
                    actionChange={values?.OTP?.length < 4 ? handleChange : () => setValues(prevValues => ({ ...prevValues, OTP: values?.OTP })) }

                    actionBlur={handleBlur}
                    label="Enter OTP"
                    validationMessage={otpError ? "Invalid OTP" : null}
                  /> */}
                  <CustomTextField
                    size="small"
                    fullWidth
                    type="number"
                    value={values.OTP}
                    onBlur={handleBlur}
                    name="OTP"
                    placeholder="otp"
                    onChange={(e) => {
                      if (e.target.value.length <= 4) {
                        handleChange(e);
                      }
                    }}
                    sx={{ marginTop: "15px" }}
                  />
                </Stack>
              )}
            </Stack>

            <Stack>
              {/* If OTP send Then show onlythe OTP text field chencking condition here*/}
              {otp ? (
                <LoadingButton
                  loading={verifyOTPLoading}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  size="large"
                  sx={{ mt: 2.5 }}
                  // onClick={() =>
                  //   handleSearch(
                  //     state.selectedStudent.map((res) => res.studentDocId)
                  //   )
                  // }
                >
                  Verify OTP
                </LoadingButton>
              ) : (
                // <Button
                //   type="submit"
                //   width={"100%"}
                //   sx={{
                //     borderRadius: "10px",
                //     background:
                //       " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
                //     height: "50px",
                //     flexShrink: 0,
                //     marginTop: "30px",
                //   }}
                //   variant="contained"
                //   size="small"
                // >
                //   Verify OTP
                // </Button>
                <LoadingButton
                  loading={sendOTPLoading}
                  type="submit"
                  color="secondary"
                  variant="contained"
                  size="large"
                  sx={{ mt: 2.5 }}
                  // onClick={() =>
                  //   handleSearch(
                  //     state.selectedStudent.map((res) => res.studentDocId)
                  //   )
                  // }
                >
                  Send OTP
                </LoadingButton>
                // <Button
                //   type="submit"
                //   width={"100%"}
                //   sx={{
                //     borderRadius: "10px",
                //     background:
                //       " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
                //     height: "50px",
                //     flexShrink: 0,
                //     marginTop: "30px",
                //   }}
                //   variant="contained"
                //   size="small"
                // >
                //   Send OTP
                // </Button>
              )}
            </Stack>
          </Box>
          <Stack sx={{ position: "absolute", bottom: "10px" }}>
            <Typography className="bottom-info">
              Don’t have an account?
              <Link to="/register" className="sing-up" href="#">
                Sign Up
              </Link>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </StyleWrapper>
  );
};

export default Login;

const StyleWrapper = styled(Box)`
  .bottom-info {
    color: rgba(2, 12, 10, 0.75);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
  }
  .buttom-info .sign-up {
    text-decoration: none;
    color: #144439;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.36px;
  }

  .title {
    text-align: center;
    color: #020c0a;
    font-size: 34.119px; /* Note: Remove the colon (:) after the value */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.024px;
    text-transform: uppercase;
  }
  .sub-title {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    color: #020c0a;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 1.024px;
    margin-top: 16px;
    opacity: 0.75;
    margin-bottom: 35px;
    font-family: inherit;
  }
`;
