import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const CommonInputSmall = (props) => {
    return (
        <>
            <StyledTable>
                <Box className="form-control">
                    <label className="label" htmlFor={props.id}>
                        {props?.children}
                        {props.label}
                    </label>
                    <br />
                    {props.open &&
                        <input
                            name={props.name || props.label || ""}
                            className="input"
                            type={props.type}
                            placeholder={props.label}
                            value={props.value}
                            onChange={props.handeler}
                        />
                    }
                </Box>
            </StyledTable>
        </>
    );
};

const StyledTable = styled(Box)`
    margin-top: 10px;
    text-align: left;
  
    .label {
        color: #020c0a;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.32px;
        padding: 10px 0;
        margin: 10px 0;
        opacity: 0.75;
        outline: none;
    }

    .input {
        width: 100%;
        box-sizing: border-box;
        border-radius: 10px;
        border: 1px solid rgba(2, 12, 10, 0.1);
        background: #fff;
        box-shadow: 0px 4px 10px 0px rgba(61, 57, 137, 0.05);
        letter-spacing: 0.4px;
        padding: 12px;
        margin-top: 10px;
        outline: none;
    }
`;

export default CommonInputSmall;