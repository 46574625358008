import {
  Button,
  Divider,
  IconButton,
  Modal,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useMutation } from "react-query";
import formatDate from "../../common/Formater";
import CommonInputSmall from "../../common/CommonInputSmall";
import styled from "styled-components";
import CustomSelector from "../../common/CustomSelector";
import { deletePhoto, updatePhoto } from "../../services/auth";
import PhotoForm from "./forms/PhotoFrom";
import { toast } from "react-toastify";

// Style for PopUp Model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  backgroundColor: "#fff",
  boxShadow: 24,
  borderRadius: "10px",
  width: "700.638px",
};

const CommonActionPhoto = ({ event, refresh }) => {
  // const event = data;
  //console.log(event,"Passed data")
  event.photoDocId = event._id;
  //console.log(event)
  const [open, setOpen] = useState({ view: false, edit: false, delete: false });

  const openModel = (name) => {
    if (name === "edit") {
      setOpen((prev) => ({ ...prev, edit: true }));
    } else if (name === "delete") {
      setOpen((prev) => ({ ...prev, delete: true }));
    } else {
      setOpen((prev) => ({ ...prev, view: true }));
    }
  };
  const closeMdedel = (name) => {
    if (name === "edit") {
      setOpen((prev) => ({ ...prev, edit: false }));
    } else if (name === "delete") {
      setOpen((prev) => ({ ...prev, delete: false }));
    } else {
      setOpen((prev) => ({ ...prev, view: false }));
    }
  };

  // Updaing Event Functionality
  const { mutate: UpdatePhoto } = useMutation(updatePhoto, {
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      refresh();
      console.log("updated event succssfully event", data);
      closeMdedel("edit");
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
      console.log("error while updating event", error.response.data.message);
    },
  });

  //deleting the event
  const { mutate: DeletePhoto } = useMutation(deletePhoto, {
    onSuccess: (data) => {
      refresh();
      console.log("delete event succssfully event", data);
      closeMdedel("edit");
    },
    onError: (error) => {
      console.log("error while delteing event", error.response.data.message);
    },
  });

  // const initialValues = {
  //   photoDocId: event._id,
  //   withDate: event?.withDate,
  //   category: event?.category, // allowed values: "quotes", "politics", "trending", "business"
  //   trendName: event?.trendName,
  //   language: event?.language,
  //   name: event?.name,
  //   tags: ["festival", "holi"],
  //   photo: event?.photo,
  // };

  //console.log("initial value s", initialValues, event);

  // //Updating Event Form dfg
  // const { values, handleChange, handleSubmit } = useFormik({
  //   event,
  //   onSubmit: (value) => {
  //     let body = {
  //       ...value,
  //     };
  //     console.log("The From is submitting", values, event._id);
  //     UpdatePhoto(body);
  //   },
  // });

  //console.log("initial value s", values);

  return (
    <Stack direction="row" spacing={0.5} sx={{ m: 0 }}>
      <IconButton sx={{ p: 0 }} onClick={() => openModel("view")}>
        <img src="./action_view.png" alt="view" />
      </IconButton>
      <IconButton sx={{ p: 0 }} onClick={() => openModel("edit")}>
        <img src="./action_edit.png" alt="edit" />
      </IconButton>
      <IconButton sx={{ p: 0 }} onClick={() => openModel("delete")}>
        <img src="./action_delete.png" alt="delete" />
      </IconButton>

      {/* View model */}
      <Modal
        open={open.view}
        onClose={() => closeMdedel("view")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledWrapper1>
          <Box sx={style} component={"form"}>
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                px: 2,
                py: 1,
              }}
            >
              <Typography varient={"h4"} sx={{ textTransform: "capitalize" }}>
                View Photo
              </Typography>
              <IconButton
                sx={{ p: 0, margin: 0 }}
                onClick={() => closeMdedel("view")}
              >
                <img alt="close" src="/close_filter.png" />
              </IconButton>
            </Stack>
            <Divider />

            <Stack direction={"column"} sx={{ px: 2, paddingBottom: "40px" }}>
              <Stack width="100%" direction={"row"} spacing={2}>
                <Box className="box-profile">
                  <Typography className="title">Event Name</Typography>
                  <Typography className="value">{event?.name}</Typography>
                </Box>
                {event?.withDate ? (
                  <Box className="box-profile">
                    <Typography className="title">Date</Typography>
                    <Typography className="value">
                      {formatDate(event?.date)}
                    </Typography>
                  </Box>
                ) : (
                  <Box className="box-profile">
                    <Typography className="title">Created Date</Typography>
                    <Typography className="value">
                      {formatDate(event?.createdOn)}
                    </Typography>
                  </Box>
                )}

                <Box className="box-profile">
                  <Typography className="title">Category</Typography>
                  <Typography className="value">
                    {event?.tags?.map((item, index) => {
                      return <span key={index}>{item}, </span>;
                    })}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </StyledWrapper1>
      </Modal>

      {/* edit model */}
      <Modal
        open={open.edit}
        onClose={() => closeMdedel("edit")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              px: 2,
              py: 1,
            }}
          >
            <Typography varient={"h4"} sx={{ textTransform: "capitalize" }}>
              Upate Photo
            </Typography>
            <IconButton
              sx={{ p: 0, margin: 0 }}
              onClick={() => closeMdedel("edit")}
            >
              <img alt="close" src="/close_filter.png" />
            </IconButton>
          </Stack>
          <Divider />
          <Box sx={{ px: 3 }}>
            <PhotoForm
              update={UpdatePhoto}
              refresh={refresh}
              initialValues={event}
              addNew={false}
            />
          </Box>
        </Box>
      </Modal>

      {/* delte model */}
      <Modal
        open={open.delete}
        onClose={() => setOpen((prev) => ({ ...prev, delete: false }))}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 300, p: 3, textAlign: "center" }}>
          <Stack sx={{ justifyContent: "center", alignItem: "center", p: 3 }}>
            <Box>
              <img width="69px" src="./delete-icon.png" alt="delete" />
            </Box>
          </Stack>
          <Typography
            variant="h6"
            className="confirm-title"
            sx={{
              color: "#020c0a",
              textAlign: "center",
              fontSize: "22px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
            }}
          >
            Are You Sure ?
          </Typography>
          <Typography
            varient="body1"
            className="confirm-description"
            sx={{
              color: "#020C0A",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              marginTop: "15px",
              opacity: "0.5",
            }}
          >
            You want to delete this Event
          </Typography>
          <Stack
            direction="row"
            justifyContent={"center"}
            sx={{ marginTop: "30px" }}
            spacing={2}
          >
            <Button
              className="confirm-button"
              type="submit"
              sx={{
                width: "95px",
                height: "50px",
                p: 1,
                marginTop: "30px",
                borderRadius: "10px",
                flexShrink: 0,
              }}
              variant="outlined"
              size="small"
              onClick={() => closeMdedel("delete")}
            >
              No
            </Button>
            <Button
              className="confirm-button"
              type="submit"
              sx={{
                width: "95px",
                height: "50px",
                p: 1,
                marginTop: "30px",
                borderRadius: "10px",
                background:
                  " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
                flexShrink: 0,
              }}
              variant="contained"
              size="small"
              onClick={() => {
                DeletePhoto({ photoDocId: event?._id });
                closeMdedel("delete");
              }}
            >
              Yes
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Stack>
  );
};

export default CommonActionPhoto;

const StyledWrapper1 = styled(Box)`
  .box-profile {
    margin-top: 15px;
    flex-shrink: 0;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.1);
    background: rgba(93, 215, 186, 0.05);
    backgroundcolor: red;
    padding: 10px;
  }
  .title {
    color: #555;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .value {
    margin-top: 5px;
    width: 184px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
    color: #555;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
`;
