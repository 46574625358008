import { Box, Button, Stack, styled, Typography } from "@mui/material";
import logo from "./img/logo.png";
import background from "./img/background.png";
import { Link, useNavigate } from "react-router-dom";
import {
  CustomEmailInput,
  CustomReactInput,
  CustomReactSelector,
} from "../../common/CommonReactInputs";
import PersonIcon from '@mui/icons-material/Person';
import CallIcon from '@mui/icons-material/Call';

//validation importings
import { useFormik } from "formik";
import { signUpSchema }from "../../schemas/Schemas"


const initialValues = {
  name:"",
  email: "",
  mobile: "",
  role:""
};

const SignUp = () => {
  const navigate = useNavigate();
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      onSubmit: (values, action) => {
        console.log(
          "🚀 ~ file: Registration.jsx ~ line 11 ~ Registration ~ values",
          values
        );
        alert("login");
        navigate('/');
        action.resetForm();
      },
    });

  // console.log(
  //   "🚀 ~ file: Registration.jsx ~ line 25 ~ Registration ~ errors",
  //   errors
  // );

  return (
    <>
      <StyleWrapper>
        <Stack width={"100%"} direction={"row"} sx={{ height: "100vh" }}>
          {/*background stack*/}
          <Stack
            sx={{
              width: "50%",
              backgroundColor: "#6FFFFF",
              backgroundImage: `url(${background})`, // Use `url()` to set the background image
              backgroundSize: "cover", // Optional: Set background size
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "264px",
                height: "264px",
                borderRadius: "100%",
                backgroundColor: "#020C0A1A",
              }}
            >
              <Stack
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "234px",
                  height: "234px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "100%",
                }}
              >
                <Box sx={{ p: 3 }}>
                  <img src={logo} alt="logo" />
                </Box>
              </Stack>
            </Stack>
          </Stack>
          {/*Form stack */}
          <Stack
            width={"50%"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ padding: "50px" }}
          >
            {/*Form Box */}
            <Box
              component={"form"}
              onSubmit={handleSubmit}
              sx={{ padding: "0 50px", position: "relative" }}
            >
              <Typography className="title" variant="h4">
                SIGNUP ACCOUNT
              </Typography>
              <Typography className="sub-title">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry.
              </Typography>
              <Stack>
                
                <CustomReactInput 
                   name="name"
                   id="name-1"
                   value={values.name}
                   actionChange={handleChange}
                   actionBlur={handleBlur}
                   label="Enter Name"
                   icon={<PersonIcon />}
                   validationMessage = {errors.name && touched.name ? (errors.name) : null}
                 />
                <CustomEmailInput
                  name="email"
                  id="email"
                  value={values.email}
                  actionChange={handleChange}
                  actionBlur={handleBlur}
                  label="Email"
                  validationMessage = {errors.email && touched.email ? (errors.email) : null}
                />

               <CustomReactInput 
                   name="mobile"
                   id="mobile"
                   value={values.mobile}
                   actionChange={handleChange}
                   actionBlur={handleBlur}
                   label="Mobile Number"
                   icon={<CallIcon />}
                   validationMessage = {errors.mobile && touched.mobile ? (errors.mobile) : null}
                 />

                 <CustomReactSelector
                   name="role"
                   id="role"
                   value={values.role}
                   actionChange={handleChange}
                   actionBlur={handleBlur}
                   label="Select Role"
                   values={["Desiner","Admin","Developer","User"]}
                   validationMessage = {errors.role && touched.role ? (errors.role) : null}
                 />

                

              </Stack>
              
              <Stack>
                <Button
                  type="submit"
                  width={"100%"}
                  sx={{
                    borderRadius: "10px",
                    background:
                      " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
                    height: "50px",
                    flexShrink: 0,
                    marginTop: "30px",
                  }}
                  variant="contained"
                  size="small"
                >
                  Sign UP
                </Button>
              </Stack>
            </Box>
            <Stack sx={{ position: "absolute", bottom: "10px" }}>
              <Typography className="bottom-info">
                If you have already an account?
                <Link to="/login" className="sing-up" href="#">
                  {" "}
                  Sign In
                </Link>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </StyleWrapper>
    </>
  );
};

export default SignUp;

const StyleWrapper = styled(Box)`
  .bottom-info {
    color: rgba(2, 12, 10, 0.75);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
  }
  .buttom-info .sign-up {
    text-decoration: none;
    color: #144439;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.36px;
  }

  .title {
    text-align: center;
    color: #020c0a;
    font-size: 34.119px; /* Note: Remove the colon (:) after the value */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.024px;
    text-transform: uppercase;
  }
  .sub-title {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    color: #020c0a;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 1.024px;
    margin-top: 16px;
    opacity: 0.75;
    margin-bottom: 35px;
    font-family: inherit;
  }
`;
