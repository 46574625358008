import { useState } from "react";
import { useQuery } from "react-query";
import { getUserList } from "../../services/auth";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CommonInputSmall from "./CommonInput";
import { toast } from "react-toastify";

const TableFilter = ({ close, setData }) => {
  const [params, setParams] = useState({});

  console.log(params, "params");

  const { refetch } = useQuery({
    queryKey: "userlist1",
    queryFn: () => getUserList(params),
    onSuccess: (userList) => {
      setData(userList.data.list);
      close();
      toast.success(userList?.data?.message);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: false,
  });

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      byName: false,
      byAge: false,
      byMobile: false,
      byEmail: false,
      byState: false,
      byDist: false,
      bySub: false,
      name: "",
      age: {
        from: "",
        to: "",
      },
      mobile: "",
      email: "",
      state: "",
      dist: "",
      subscriptiontype: "",
    },
    onSubmit: async (formData) => {
      const body = {
        ...(formData?.byName ? { firstName: formData.name } : {}),
        ...(formData?.byAge
          ? { from: formData.age.from, to: formData.age.to }
          : {}),
        ...(formData?.byState ? { state: formData.state } : {}),
        ...(formData?.byDist ? { dist: formData.dist } : {}),
        ...(formData?.byEmail ? { email: formData.email } : {}),
        ...(formData?.byMobile ? { mobileNumber: formData.mobile } : {}),
      };

      setParams(body);
      refetch();
    },
  });

  return (
    <Box width="300px">
      <Box sx={{ p: 1 }}>
        <Stack
          direction={"row"}
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Typography varient={"h4"}>User List</Typography>
          <IconButton sx={{ p: 0, margin: 0 }} onClick={close}>
            <img alt="close" src="/close_filter.png" />
          </IconButton>
        </Stack>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }} component="form" onSubmit={handleSubmit}>
        <CommonInputSmall
          id="name"
          label="By Name"
          type="text"
          value={values.name}
          name="name"
          handeler={handleChange}
          open={values.byName}
        >
          <input
            type="checkbox"
            value={values.byName}
            name="byName"
            onChange={handleChange}
          />
        </CommonInputSmall>

        <Box sx={{ margin: "10px 0" }}>
          <Typography className="label">
            <input
              type="checkbox"
              value={values.byAge}
              name="byAge"
              onChange={handleChange}
            />
            Age Group
          </Typography>
          {values?.byAge && (
            <Stack
              spacing={2}
              direction="row"
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <CommonInputSmall
                id="start_num"
                label="From"
                type="number"
                name="age.from"
                value={values.age.from}
                handeler={handleChange}
                open={values.byAge}
              />
              <CommonInputSmall
                id="end_num"
                label="To"
                type="number"
                name="age.to"
                value={values.age.to}
                open={values.byAge}
                handeler={handleChange}
              />
            </Stack>
          )}
        </Box>

        <CommonInputSmall
          id="mobile"
          label="Mobile Number"
          type="text"
          name="mobile"
          value={values.mobile}
          open={values.byMobile}
          handeler={handleChange}
        >
          <input
            type="checkbox"
            value={values.byMobile}
            name="byMobile"
            onChange={handleChange}
          />
        </CommonInputSmall>

        <CommonInputSmall
          id="email"
          label="Email"
          type="email"
          name="email"
          value={values.email}
          open={values.byEmail}
          handeler={handleChange}
        >
          <input
            type="checkbox"
            value={values.byEmail}
            name="byEmail"
            onChange={handleChange}
          />
        </CommonInputSmall>

        <CommonInputSmall
          id="state"
          label="State"
          type="text"
          name="state"
          value={values.state}
          open={values.byState}
          handeler={handleChange}
        >
          <input
            type="checkbox"
            value={values.byState}
            name="byState"
            onChange={handleChange}
          />
        </CommonInputSmall>

        <CommonInputSmall
          id="district"
          label="District"
          type="text"
          name="dist"
          value={values.dist}
          open={values.byDist}
          handeler={handleChange}
        >
          <input
            type="checkbox"
            value={values.byDist}
            name="byDist"
            onChange={handleChange}
          />
        </CommonInputSmall>

        <CommonInputSmall
          id="subscription"
          label="Subscription Type"
          type="text"
          name="subscriptiontype"
          value={values.subscriptiontype}
          open={values.bySub}
          handeler={handleChange}
        >
          <input
            type="checkbox"
            value={values.bySub}
            name="bySub"
            onChange={handleChange}
          />
        </CommonInputSmall>

        <Stack>
          <Button
            type="submit"
            width={"100%"}
            sx={{
              p: 1,
              borderRadius: "10px",
              background: " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
              flexShrink: 0,
              marginTop: "30px",
            }}
            variant="contained"
            size="small"
          >
            Apply
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default TableFilter;
