import { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation } from "react-query";
import DialogHeader from "../../common/DialogHeader";
import LocationName from "./LocationName";
import { updateContentLocation } from "../../services/auth";

const logoFormikValidationSchema = yup.object({
  x_axis: yup.string().required("Required"),
  y_axis: yup.string().required("Required"),
  fontColor: yup.string().optional(""),
  fontWeight: yup.string().required("Required"),
  fontSize: yup.string().required("Required"),
  fontStyle: yup.string().required("Required"),
});

const emailFormikValidationSchema = yup.object({
  x_axis: yup.string().required("Required"),
  y_axis: yup.string().required("Required"),
  fontColor: yup.string().optional(""),
  fontWeight: yup.string().required("Required"),
  fontSize: yup.string().required("Required"),
  fontStyle: yup.string().required("Required"),
});

const UpdateContentLocation = ({
  refetch = () => {},
  open,
  handleClose = () => {},
  data,
}) => {
  const logoFormik = useFormik({
    initialValues: {
      x_axis: "100",
      y_axis: "100",
      fontColor: "#000",
      fontWeight: "500",
      fontSize: "14",
      fontStyle: "normal",
    },
    validationSchema: logoFormikValidationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const emailFormik = useFormik({
    initialValues: {
      x_axis: "10",
      y_axis: "50",
      fontColor: "#000",
      fontWeight: "500",
      fontSize: "14",
      fontStyle: "normal",
    },
    validationSchema: emailFormikValidationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const adressFormik = useFormik({
    initialValues: {
      x_axis: "10",
      y_axis: "100",
      fontColor: "#000",
      fontWeight: "500",
      fontSize: "14",
      fontStyle: "normal",
    },
    validationSchema: emailFormikValidationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const whatsappFormik = useFormik({
    initialValues: {
      x_axis: "10",
      y_axis: "150",
      fontColor: "#000",
      fontWeight: "500",
      fontSize: "14",
      fontStyle: "normal",
    },
    validationSchema: emailFormikValidationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const phoneFormik = useFormik({
    initialValues: {
      x_axis: "10",
      y_axis: "200",
      fontColor: "#000",
      fontWeight: "500",
      fontSize: "14",
      fontStyle: "normal",
    },
    validationSchema: emailFormikValidationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const websiteFormik = useFormik({
    initialValues: {
      x_axis: "10",
      y_axis: "250",
      fontColor: "#000",
      fontWeight: "500",
      fontSize: "14",
      fontStyle: "normal",
    },
    validationSchema: emailFormikValidationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const [openedCard, setOpenedCard] = useState(null);

  const {
    mutate: updateContentLocationMutate,
    isLoading: updateContentLocationLoading,
  } = useMutation(updateContentLocation, {
    onSuccess: (data) => {
      handleClose();
      refetch();
    },
    onError: (error) => {
      // toast.error("Failed to update content location");
    },
  });

  const handleLocationUpdate = () => {
    let body = {
      frameDocId: data?._id,
      contentLocation: {
        logo: {
          x_axis: logoFormik?.values?.x_axis,
          y_axis: logoFormik?.values?.y_axis,
          fontColor: String(logoFormik?.values?.fontColor),
          fontWeight: logoFormik?.values?.fontWeight,
          fontSize: logoFormik?.values?.fontSize,
          fontStyle: logoFormik?.values?.fontStyle,
        },
        email: {
          x_axis: emailFormik?.values?.x_axis,
          y_axis: emailFormik?.values?.y_axis,
          fontColor: String(emailFormik?.values?.fontColor),
          fontWeight: emailFormik?.values?.fontWeight,
          fontSize: emailFormik?.values?.fontSize,
          fontStyle: emailFormik?.values?.fontStyle,
        },
        address: {
          x_axis: adressFormik?.values?.x_axis,
          y_axis: adressFormik?.values?.y_axis,
          fontColor: String(adressFormik?.values?.fontColor),
          fontWeight: adressFormik?.values?.fontWeight,
          fontSize: adressFormik?.values?.fontSize,
          fontStyle: adressFormik?.values?.fontStyle,
        },
        whatsappNumber: {
          x_axis: whatsappFormik?.values?.x_axis,
          y_axis: whatsappFormik?.values?.y_axis,
          fontColor: String(whatsappFormik?.values?.fontColor),
          fontWeight: whatsappFormik?.values?.fontWeight,
          fontSize: whatsappFormik?.values?.fontSize,
          fontStyle: whatsappFormik?.values?.fontStyle,
        },
        mobileNumber: {
          x_axis: phoneFormik?.values?.x_axis,
          y_axis: phoneFormik?.values?.y_axis,
          fontColor: String(phoneFormik?.values?.fontColor),
          fontWeight: phoneFormik?.values?.fontWeight,
          fontSize: phoneFormik?.values?.fontSize,
          fontStyle: phoneFormik?.values?.fontStyle,
        },
        website: {
          x_axis: websiteFormik?.values?.x_axis,
          y_axis: websiteFormik?.values?.y_axis,
          fontColor: String(websiteFormik?.values?.fontColor),
          fontWeight: websiteFormik?.values?.fontWeight,
          fontSize: websiteFormik?.values?.fontSize,
          fontStyle: websiteFormik?.values?.fontStyle,
        },
      },
    };
    console.log("handel submit", body);
    updateContentLocationMutate(body);
  };

  useEffect(() => {
    if (open && data?.contentLocation) {
      logoFormik?.setValues(data?.contentLocation?.logo);
      emailFormik?.setValues(data?.contentLocation?.email);
      adressFormik?.setValues(data?.contentLocation?.address);
      whatsappFormik?.setValues(data?.contentLocation?.whatsappNumber);
      phoneFormik?.setValues(data?.contentLocation?.mobileNumber);
      websiteFormik?.setValues(data?.contentLocation?.website);
    }
  }, [open]);

  console.log(emailFormik, "emailFormik?.valuessdasdsxas");

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            minWidth: "60vw",
          },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            title="Update Content Location"
            handleClose={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ m: 2 }}>
            <Stack direction={{ xs: "column", lg: "row" }} gap={2}>
              <Stack
                width={"50%"}
                position={"sticky"}
                style={{
                  backgroundImage: `${data?.framePic}`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <Stack
                  style={{
                    position: "relative",
                    height: "500px",
                  }}
                >
                  <img
                    src={data?.framePic}
                    alt="image"
                    style={{
                      position: "static",
                      height: data.height ?? 375,
                      width: data.width ?? 375,
                    }}
                  />
                </Stack>
                {/* logo */}
                <Typography
                  sx={{
                    fontSize: `${logoFormik?.values?.fontSize}px`,
                    fontWeight: `${logoFormik?.values?.fontWeight}`,
                    position: "absolute",
                    zIndex: 1,
                    color: logoFormik?.values?.fontColor ?? "#000000",
                    top: `${logoFormik?.values?.y_axis}px` ?? 0,
                    left: `${logoFormik?.values?.x_axis}px` ?? 0,
                    fontStyle: `${logoFormik?.values?.fontStyle}` ?? "normal",
                  }}
                >
                  <img width="100px" src="./frame-log.jpg" />
                </Typography>
                {/* email */}
                <Typography
                  sx={{
                    fontSize: `${emailFormik?.values?.fontSize}px` ?? "12px",
                    fontWeight: `${emailFormik?.values?.fontWeight}` ?? "500",
                    position: "absolute",
                    zIndex: 1,
                    color: emailFormik?.values?.fontColor ?? "#000000",
                    top: `${emailFormik?.values?.y_axis}px` ?? 10,
                    left: `${emailFormik?.values?.x_axis}px` ?? 10,
                    fontStyle: `${emailFormik?.values?.fontStyle}` ?? "normal",
                  }}
                >
                  contact@ekalsutra.com
                </Typography>
                {/* adress */}
                <Typography
                  sx={{
                    fontSize: `${adressFormik?.values?.fontSize}px`,
                    fontWeight: `${adressFormik?.values?.fontWeight}`,
                    position: "absolute",
                    zIndex: 1,
                    color: adressFormik?.values?.fontColor ?? "#000000",
                    top: `${adressFormik?.values?.y_axis}px` ?? 20,
                    left: `${adressFormik?.values?.x_axis}px` ?? 20,
                    fontStyle: `${adressFormik?.values?.fontStyle}` ?? "normal",
                  }}
                >
                  ADRESS
                </Typography>
                {/* whatsapp */}
                <Typography
                  sx={{
                    fontSize: `${whatsappFormik?.values?.fontSize}px`,
                    fontWeight: `${whatsappFormik?.values?.fontWeight}`,
                    position: "absolute",
                    zIndex: 1,
                    color: whatsappFormik?.values?.fontColor ?? "#000000",
                    top: `${whatsappFormik?.values?.y_axis}px` ?? 20,
                    left: `${whatsappFormik?.values?.x_axis}px` ?? 30,
                    fontStyle:
                      `${whatsappFormik?.values?.fontStyle}` ?? "normal",
                  }}
                >
                  WHATSAPP
                </Typography>
                <Typography
                  sx={{
                    fontSize: `${phoneFormik?.values?.fontSize}px`,
                    fontWeight: `${phoneFormik?.values?.fontWeight}`,
                    position: "absolute",
                    zIndex: 1,
                    color: phoneFormik?.values?.fontColor ?? "#000000",
                    top: `${phoneFormik?.values?.y_axis}px` ?? 30,
                    left: `${phoneFormik?.values?.x_axis}px` ?? 20,
                    fontStyle: `${phoneFormik?.values?.fontStyle}` ?? "normal",
                  }}
                >
                  PHONE
                </Typography>
                <Typography
                  sx={{
                    fontSize: `${websiteFormik?.values?.fontSize}px`,
                    fontWeight: `${websiteFormik?.values?.fontWeight}`,
                    position: "absolute",
                    zIndex: 1,
                    color: websiteFormik?.values?.fontColor ?? "#000000",
                    top: `${websiteFormik?.values?.y_axis}px` ?? 40,
                    left: `${websiteFormik?.values?.x_axis}px` ?? 20,
                    fontStyle:
                      `${websiteFormik?.values?.fontStyle}` ?? "normal",
                  }}
                >
                  WEBSITE
                </Typography>
              </Stack>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {/* for logo purpose */}
                  <LocationName
                    item={"logo"}
                    openedCard={openedCard}
                    setOpenedCard={setOpenedCard}
                    formik={logoFormik}
                  />
                  {/* for email pupose */}
                  <LocationName
                    item={"email"}
                    openedCard={openedCard}
                    setOpenedCard={setOpenedCard}
                    formik={emailFormik}
                  />
                  {/* for address puporse */}
                  <LocationName
                    item={"adress"}
                    formik={adressFormik}
                    openedCard={openedCard}
                    setOpenedCard={setOpenedCard}
                  />
                  {/* for whatsapp purpose */}
                  <LocationName
                    item={"whatsapp"}
                    formik={whatsappFormik}
                    openedCard={openedCard}
                    setOpenedCard={setOpenedCard}
                  />
                  {/* for phone purpose */}
                  <LocationName
                    item={"phone"}
                    formik={phoneFormik}
                    openedCard={openedCard}
                    setOpenedCard={setOpenedCard}
                  />
                  {/* for website purpose */}
                  <LocationName
                    item={"website"}
                    formik={websiteFormik}
                    openedCard={openedCard}
                    setOpenedCard={setOpenedCard}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={() => {
              handleLocationUpdate();
            }}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateContentLocation;
