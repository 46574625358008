import React from "react";
import { Navigate } from "react-router-dom";
import { PATH_CONSTANTS } from "../utils/constants";
import { useSelector } from "react-redux";


const AdminProtectedRoute = ({ children }) => {
  const isAuthenticated = useSelector((store)=> store.auth.isAuthenticated);
  return isAuthenticated ? children : <Navigate to={PATH_CONSTANTS.login} />;
};

export default AdminProtectedRoute;
