import { styled, Box, Typography, Stack, Button } from "@mui/material";
import {
  // COLORS_CONSTANTS,
  PATH_CONSTANTS,
  //SIZES_CONSTANTS,
} from "../utils/constants";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogout } from "../services/redux/authSlice";

const sidebarItem = [
  {
    name: "Dashboard",
    path: PATH_CONSTANTS.dashboard,
    image: "/Group.png",
  },
  {
    name: "User List",
    path: PATH_CONSTANTS.userList,
    image: "/3 User.png",
  },
  {
    name: "Staff List",
    path: PATH_CONSTANTS.staffList,
    image: "/staff.png",
  },
  {
    name: "Calendar",
    path: PATH_CONSTANTS.calendar,
    image: "/calendar.png",
  },
  {
    name: "Upload",
    path: PATH_CONSTANTS.upload,
    image: "/upload.png",
  },
  // {
  //   name: "Events",
  //   path: PATH_CONSTANTS.event,
  //   image: "/Group.png",
  // },
];
const Sidebar = () => {
  
  const location = useLocation();
  const dispatch = useDispatch();

  const logout = ()=>{
     dispatch(setLogout());
  }
  return (
    <StyledWrapper >
      <Box sx={{ height: "15vh", p: 3,m:0 }}>
        <Link to="/">
           <img alt="logo" src="./logo1.png" className="logo"/>
        </Link>
      </Box>
      <Box my={"5"} sx={{ height: "63vh", p:3,}}>
        {sidebarItem?.map((item, index) => (
          <Link to={item.path} style={{textDecoration: "none"}} key={index}>
            <Stack
              direction={"row"}
              gap={2}
              className={`menut_item ${
                location.pathname === item.path && "menu_item__active"
              }`}
            >
              <img alt={item.name} src={item.image} className="sidebar_icon" />

              <Typography className="sidebar_menu_name">
                {item?.name}
              </Typography>
            </Stack>
          </Link>
        ))}
      </Box>
      <Box sx={{ height: "10vh", p: 3 }}>
        <Button onClick={logout}>
        <Stack direction={"row"} gap={2} sx={{ ml: "2", p: 2 }}>
          <img alt="logout" src="/logout.png" className="sidebar_icon" />
          <Link
            // to={PATH_CONSTANTS.dashboard}
            style={{ textDecoration: "none" }}
          >
            <Typography className="sidebar_menu_name" >Log Out</Typography>
          </Link>
        </Stack>
        </Button>
      </Box>
    </StyledWrapper>
  );
};

export default Sidebar;
const StyledWrapper = styled(Box)`
//background: linear-gradient(-25deg ,#31B696 50% );
  display:{
    sm:"none";
  }
   
   .logo{
     padding:20px 40px;
   }
  .poster_text {
    color: #1b5447;
    text-align: center;
    font-size: 28.615px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .menut_item {
    margin-left:0;
    padding: 16px;
    margin:16px 0;
  }
  .menu_item__active {
    background-color: #1a5346;
    border-radius: 15px;
    border: 1px solid rgba(2, 12, 10, 0.05);
    box-shadow: 0px 4px 10px 0px rgba(61, 57, 137, 0.05);
  }

  .maker_text {
    color: #fff;
    text-align: center;
  
    font-size: 28.615px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .sidebar_icon {
    width: 23.04px;
    height: 23.085px;
    text-align: center;
    object-fit: fill;
  }
  .sidebar_menu_name {
    color: #fff;

    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.48px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
  }
`;
