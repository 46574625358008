import React, { useEffect, useState } from "react";
import { Divider, Grid, MenuItem, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as yup from "yup";

import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import TextFieldLabel from "../../common/TextFieldLabel";
import {
  CustomSelectBox,
  CustomTextField,
} from "../../common/globalStyledComponent";
import CommonDialog from "../../common/CommonDialog";
import DialogHeader from "../../common/DialogHeader";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { SketchPicker } from "react-color";
import { red } from "@mui/material/colors";

const LocationName = ({
  refetch = () => {},
  open,
  handleClose = () => {},
  data,
  item,
  formik,
  openedCard,
  setOpenedCard,
}) => {
  const [color, setColor] = useState({
    ColorPickerOpen: false,
    color: "#fff",
  });
  console.log("formik", formik?.values);
  const handleClick = () => {
    setColor({ ...color, ColorPickerOpen: !color.ColorPickerOpen });
  };

  const handleCloseColorPicker = () => {
    setColor({ ...color, ColorPickerOpen: false });
  };

  const handleColorChange = (color) => {
    console.log(color, "in the handle color change");
    setColor({ ...color, color: color.hex });
    formik?.setValues((prev) => ({
      ...prev,
      fontColor: color?.hex,
    }));
  };

  // const [showDropDown, setShowDropDown] = React.useState(false);
  const handleDropDown = () => {
    // setShowDropDown(!showDropDown);
    if (openedCard === item) {
      setOpenedCard(null);
    } else {
      setOpenedCard(item);
    }
  };

  const showDropDown = openedCard === item;

  return (
    <>
      <Stack
        sx={{
          border: 1,
          borderRadius: 2,
          borderColor: "primary.rnPrimary",
          boxShadow: "0px 4px 10px rgba(61, 57, 137, 0.06)",
          justifyContent: "space-between",
          backgroundColor: "#00CC8C10",
          padding: 0.5,
          mb: 2,
        }}
      >
        <Stack
          direction={"row"}
          onClick={handleDropDown}
          sx={{
            justifyContent: "space-between",
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{
              color: "primary.main",
              cursor: "pointer",
            }}
          >
            {item}
          </Typography>
          {showDropDown ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </Stack>
        {showDropDown && (
          <Divider
            sx={{
              width: "101%",
              alignSelf: "center",
              backgroundColor: "primary.rnPrimary",
            }}
          />
        )}
        {showDropDown && (
          <>
            <Grid container spacing={2} sx={{ padding: "15px 0" }}>
              <Grid item xs={3}>
                {/* <Typography
                  sx={{
                    color: "text.secondary",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  x_axis
                </Typography> */}

                <CustomTextField
                  fullWidth
                  label="x_axis"
                  variant="outlined"
                  size="small"
                  name="x_axis"
                  type="number"
                  value={formik?.values?.x_axis}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched.x_axis && Boolean(formik?.errors.x_axis)
                  }
                  helperText={formik?.touched.x_axis && formik?.errors.x_axis}
                />
              </Grid>
              <Grid item xs={3}>
                {/* <Typography
                  sx={{
                    color: "text.secondary",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  y_axis
                </Typography> */}

                <CustomTextField
                  fullWidth
                  label="y_axis"
                  variant="outlined"
                  size="small"
                  name="y_axis"
                  type="number"
                  value={formik?.values?.y_axis}
                  onChange={formik?.handleChange("y_axis")}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched.y_axis && Boolean(formik?.errors.y_axis)
                  }
                  helperText={formik?.touched.y_axis && formik?.errors.y_axis}
                />
              </Grid>
              <Grid item xs={4} flexDirection={"row"}>
                <Grid>
                </Grid>

                <Stack
                  onClick={handleClick}
                  sx={{
                    border: 1,
                    borderRadius: 2,
                    borderColor: "rgba(208, 208, 226, 1)",
                    boxShadow: "0px 4px 10px rgba(61, 57, 137, 0.06)",
                    height: 40,
                    width: 80,
                    backgroundColor: formik?.values?.fontColor ?? "#fff",
                  }}
                />
                {color?.ColorPickerOpen && (
                  <SketchPicker
                    color={formik?.values?.color}
                    onChange={handleColorChange}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {/* <Typography
                  sx={{
                    color: "text.secondary",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  fontWeight
                </Typography> */}

                <CustomTextField
                  fullWidth
                  label="fontWeight"
                  variant="outlined"
                  size="small"
                  name="fontWeight"
                  type="number"
                  value={formik?.values?.fontWeight}
                  onChange={formik?.handleChange("fontWeight")}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.fontWeight &&
                    Boolean(formik?.errors?.fontWeight)
                  }
                  helperText={
                    formik?.touched?.fontWeight && formik?.errors?.fontWeight
                  }
                />
              </Grid>
              <Grid item xs={6}>
                {/* <Typography
                  sx={{
                    color: "text.secondary",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  fontSize
                </Typography> */}

                <CustomTextField
                  fullWidth
                  label="fontSize"
                  variant="outlined"
                  size="small"
                  name="fontSize"
                  type="number"
                  value={formik?.values?.fontSize}
                  onChange={formik?.handleChange("fontSize")}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.fontSize &&
                    Boolean(formik?.errors?.fontSize)
                  }
                  helperText={
                    formik?.touched?.fontSize && formik?.errors?.fontSize
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  fontStyle
                </Typography>

                <CustomSelectBox
                  name="fontStyle"
                  onBlur={formik?.handleBlur}
                  displayEmpty
                  fullWidth
                  value={formik?.values?.fontStyle}
                  onChange={formik?.handleChange}
                  error={
                    formik?.touched?.fontStyle && formik?.errors?.fontStyle
                  }
                >
                  <MenuItem value="">Select</MenuItem>
                  {["normal", "italic", "oblique"]?.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </CustomSelectBox>
                {formik?.touched?.fontStyle && formik?.errors?.fontStyle && (
                  <Typography sx={{ color: "red" }}>
                    {formik?.errors?.fontStyle}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Stack>
    </>
  );
};

export default LocationName;
