import axios from "axios";
import { axiosInstance } from "../axios";

const BASE_URL = process.env.REACT_APP_BASE;

// Authentication

export const login = (body) => {
  return axios.post(`${BASE_URL}/auth/SendOTPonMobile`, body);
};

export const SignInWithOTP = (body) => {
  return axios.post(`${BASE_URL}/auth/SignInWithOTP`, body);
};

export const getUserProfile = () => {
  return axiosInstance.get(`${BASE_URL}/userSelf/getUserProfile`);
};

export const updateSelfProfile = (body) => {
  return axiosInstance.put(`${BASE_URL}/userSelf/updateSelfProfile`, body);
};

//user list
//user/getUserInfo
export const getUserList = (params) => {
  return axiosInstance.get(`${BASE_URL}/user/getUserList`, { params });
};

export const getUserInfo = () => {
  return axiosInstance.get(`${BASE_URL}/user/getUserInfo`);
};

export const getOnboardedUserCount = () => {
  return axiosInstance.get(`${BASE_URL}/user/getOnboardedUserCount`);
};

// /staff/getStaffList
export const getStaffList = (params) => {
  return axiosInstance.get(`${BASE_URL}/staff/getStaffList`, { params });
};
export const changeDesignation = (body) => {
  console.log(body);
  return axiosInstance.put(`${BASE_URL}/user/changeDesignation`, body);
};

///event/getEvent
export const getOrgEvent = (params) => {
  return axiosInstance.get(`${BASE_URL}/orgEvent/getOrgEvent`, { params });
};
export const addOrgEvent = (body) => {
  return axiosInstance.post(`${BASE_URL}/orgEvent/addOrgEvent`, body);
};
export const updateEvent = (body) => {
  return axiosInstance.put(`${BASE_URL}/orgEvent/updateOrgEvent`, body);
};
export const deleteEvent = (params) => {
  const eventDocId = params?.eventDocId;
  return axiosInstance.delete(
    `${BASE_URL}/orgEvent/deleteOrgEvent/${eventDocId}`
  );
};

///content/addFrame
export const addFrame = (body) => {
  return axiosInstance.post(`${BASE_URL}/frame/addOrgFrame`, body);
};
export const getOrgFrame = (params) => {
  return axiosInstance.get(`${BASE_URL}/frame/getOrgFrame`, { params });
};
export const updateFrame = (body) => {
  return axiosInstance.put(`${BASE_URL}/frame/updateOrgFrame`, body);
};
export const updateContentLocation = (body) => {
  return axiosInstance.put(`${BASE_URL}/frame/updateContentLocation`, body);
};
export const deleteFrame = (params) => {
  if (!params?.frameDocId) {
    return;
  }
  return axiosInstance.delete(
    `${BASE_URL}/frame/deleteOrgFrame/` + params?.frameDocId
  );
};

// photo
export const addPhoto = (body) => {
  return axiosInstance.post(`${BASE_URL}/photo/addOrgPhoto`, body);
};
export const getPhoto = (params) => {
  return axiosInstance.get(`${BASE_URL}/photo/getOrgPhoto`, { params });
};
export const updatePhoto = (body) => {
  return axiosInstance.put(`${BASE_URL}/photo/updateOrgPhoto`, body);
};
export const deletePhoto = (params) => {
  if (!params?.photoDocId) {
    return;
  }
  return axiosInstance.delete(
    `${BASE_URL}/photo/deleteOrgPhoto/` + params.photoDocId
  );
};

//dashboard
export const getUserCount = () => {
  return axiosInstance.get(`${BASE_URL}/dashboard/getUserCount`);
};

export const getStaffCount = () => {
  return axiosInstance.get(`${BASE_URL}/dashboard/getStaffCount`);
};

export const getTotalContent = () => {
  return axiosInstance.get(`${BASE_URL}/dashboard/getTotalContent`);
};

export const getTotalBusinessCount = () => {
  return axiosInstance.get(`${BASE_URL}/dashboard/getTotalBusinessCount`);
};
