import {
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
// import { Visibility, VisibilityOff, EmailIcon } from "@mui/icons-material";
import React from "react";

const CommonReactInputs = (props) => {
  //
  return (
    <StyledWrapper>
      <InputLabel className="label" htmlFor="outlined-multiline-flexible">
        {props.label ? props.label : ""}
      </InputLabel>
      <TextField
        className="input"
        id="outlined-multiline-flexible" // should be change
        variant="outlined"
        fullWidth
        InputProps={{
          endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          sx: { borderRadius: "10px" },
        }}
        sx={{ borderRadius: "10px" }}
      />
    </StyledWrapper>
  );
};
export default CommonReactInputs;

export const CustomReactInput = (props) => {
  return (
    <StyledWrapper>
      <InputLabel className="label" htmlFor={props.id ? props.id : ""}>
        {props.label ? props.label : ""}
      </InputLabel>
      <TextField
        type={props.type ? props.type : "text"}
        autoComplete="off"
        name={props.name ? props.name : ""}
        id={props.id ? props.id : ""}
        placeholder={props.placeholder ? props.placeholder : props.name}
        value={props.value}
        onChange={props.actionChange}
        onBlur={props.actionBlur}
        className="input"
        variant="outlined"
        fullWidth
        // helperText={
        //   <FormHelperText
        //     sx={{
        //       fontSize: "12px", // Customize font size
        //       color: "red", // Customize text color
        //       marginTop: "5px", // Customize margin-top
        //       // Add more styles as needed
        //     }}
        //   >
        //     {props.validationMessage}
        //   </FormHelperText>
        // }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {props.icon ? props.icon : ""}
            </InputAdornment>
          ),
          sx: { borderRadius: "10px" },
        }}
        sx={{ borderRadius: "10px" }}
      />
      <Typography className="form-error">{props.validationMessage}</Typography>
    </StyledWrapper>
  );
};
export const CustomEmailInput = (props) => {
  return (
    <StyledWrapper>
      <InputLabel className="label" htmlFor={props.id ? props.id : "name"}>
        {props.label ? props.label : ""}
      </InputLabel>
      <TextField
        type="text"
        autoComplete="off"
        name={props.name ? props.name : "name"}
        id={props.id ? props.id : "name"}
        placeholder={props.placeholder ? props.placeholder : props.name}
        value={props.value}
        onChange={props.actionChange}
        onBlur={props.actionBlur}
        className="input"
        variant="outlined"
        fullWidth
        // helperText={
        //   <FormHelperText
        //     sx={{
        //       fontSize: "12px", // Customize font size
        //       color: "red", // Customize text color
        //       marginTop: "5px", // Customize margin-top
        //       // Add more styles as needed
        //     }}
        //   >
        //     {props.validationMessage}
        //   </FormHelperText>
        // }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {/* <EmailIcon /> */}
              e
            </InputAdornment>
          ),
          sx: { borderRadius: "10px" },
        }}
        sx={{ borderRadius: "10px" }}
      />
      <Typography className="form-error">{props.validationMessage}</Typography>
    </StyledWrapper>
  );
};

export const CustomReactSelector = (props) => {
  return (
    <StyledWrapper>
      <InputLabel className="label" htmlFor={props.id ? props.id : "name"}>
        {props.label ? props.label : ""}
      </InputLabel>
      <Select
        className="input"
        name="role"
        value={props.value}
        onChange={props.actionChange}
        onBlur={props.actionBlur}
        displayEmpty
        defaultValue="select"
        fullWidth
        inputProps={{ "aria-label": "Without label" }}
        sx={{ borderRadius: "10px" }}
      >
        <MenuItem value="select" disabled>
          Select
        </MenuItem>
        {props.values.map((value, index) => (
          <MenuItem key={index} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
      <Typography className="form-error">{props.validationMessage}</Typography>
    </StyledWrapper>
  );
};

export const CustomPasswordInput = (props) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <StyledWrapper>
      <InputLabel className="label" htmlFor={props.id ? props.id : "name"}>
        {props.label ? props.label : ""}
      </InputLabel>
      <TextField
        className="input"
        type={showPassword ? "text" : "password"}
        autoComplete="off"
        name={props.name ? props.name : "name"}
        id={props.id ? props.id : "name"}
        placeholder={props.placeholder ? props.placeholder : props.name}
        value={props.value}
        onChange={props.actionChange}
        onBlur={props.actionBlur}
        variant="outlined"
        fullWidth
        // helperText={
        //   <FormHelperText
        //     sx={{
        //       fontSize: "12px", // Customize font size
        //       color: "red", // Customize text color
        //       marginTop: "5px", // Customize margin-top
        //       // Add more styles as needed
        //     }}
        //   >
        //     {props.validationMessage}
        //   </FormHelperText>
        // }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
              </IconButton>
            </InputAdornment>
          ),
          sx: { borderRadius: "10px" },
        }}
        sx={{ borderRadius: "10px" }}
      />
      <Typography className="form-error">{props.validationMessage}</Typography>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Box)`
  margin-top: 5px;
  .label {
    width: 100%;
    color: #020c0a;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.54px;
    padding: 10px 0;
    opacity: 0.75;
    outline: none;
  }
  .input {
    margin: 5px 0;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(61, 57, 137, 0.05);
    width: 100%;
    height: 60px;
    flex-shrink: 0;
    color: #020c0a;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
  }
  .form-error {
    color: red;
    margin-bottom: 5px;
    margin-top: 0;
  }
`;
