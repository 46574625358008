import { useState } from "react";
import { Button, Drawer, IconButton, Stack, Typography } from "@mui/material";
import TableFilter from "./TableFilter";

const MyMenu = (props) => {
  const [showHide, setShowHide] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowHide(open);
  };
  return (
    <>
      <Stack width={"100%"} direction={"row"}>
        <Stack sx={{ justifyContent: "flex-end" }} width={"50%"}>
          <Typography varient={"h4"} className="over-view-data-title">
            {props.name}
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          width={"50%"}
          sx={{ justifyContent: "flex-end", alignItems: "center" }}
        >
          {props.total && (
            <Typography
              varient={"h4"}
              className="over-view-data-title"
              sx={{ marginRight: "15px" }}
            >
              Total :{props?.total}
            </Typography>
          )}
          <Button
            variant="contained"
            className="filter-button"
            onClick={toggleDrawer(true)}
          >
            Apply Filter
          </Button>
          <Drawer
            anchor={"right"}
            open={showHide}
            onClose={toggleDrawer(false)}
          >
            <TableFilter
              close={() => setShowHide(false)}
              RefetchUser={props?.RefetchUser}
              setData={props?.setData}
            />
          </Drawer>
          {/* 
                    <Stack
                        direction={"row"}
                        sx={{justifyContent: "start", alignItems: "center"}}
                    >
                        <Typography
                            component={"h4"}
                            className="export-label"
                            sx={{margin: "17px"}}
                        >
                            Export :-
                        </Typography>
                        <IconButton>
                            <img src="/xcel.png" alt="xcel"/>
                        </IconButton>
                        <IconButton>
                            <img src="/doc.png" alt="xcel"/>
                        </IconButton>
                        <IconButton>
                            <img src="/pdf.png" alt="xcel"/>
                        </IconButton>
                    </Stack> */}
        </Stack>
      </Stack>
    </>
  );
};

export default MyMenu;
