import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import ThemeProvider from "react-bootstrap/ThemeProvider";

import { MainRoute } from "./routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./services/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createTheme,
  ThemeProvider as MaterialThemeProvider,
} from "@mui/material";
import { NotificationContainer } from "react-notifications";
import { FormioStyleOverrider } from "./components/FormioStyleOverrider";
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const theme = createTheme({
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#0C2F49",
      rnPrimary: "#00CC8C",
      rnsecondary: "#184D41",
      rnplaceholder: "#fff",
      rnoutline: "rgba(24,77,65,0.5)",
      rnsurface: "rgba(24,77,65,0.5)",
      rnsurfaceVariant: "rgba(24,77,65,0.5)",
      rnbackground: "#f5f5f5",
    },
    secondary: {
      main: "rgba(27, 84, 71, 100%)",
    },
    text: {
      primary: "#0C2F49",
    },
  },
});
function App() {
  return (
    <>
      <FormioStyleOverrider id="app">
        <QueryClientProvider client={queryClient}>
          <MaterialThemeProvider theme={theme}>
            <BrowserRouter>
              <ReduxProvider store={store}>
                <ThemeProvider>
                  <NotificationContainer />
                  <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                    theme="colored"
                  />
                  <MainRoute />
                </ThemeProvider>
              </ReduxProvider>
            </BrowserRouter>
          </MaterialThemeProvider>
        </QueryClientProvider>
      </FormioStyleOverrider>
    </>
  );
}

export default App;
