import { Box, IconButton, Typography, styled } from "@mui/material";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { saveAs, FileSaverOptions } from "file-saver";
import { Close } from "@mui/icons-material";
const PreviewDownloadImage = ({
  src,
  documentName,
  deleteIcon = false,
  handleDelete = () => {},
}) => {
  const name = src?.substring(src.lastIndexOf("/") + 1);
  const handleDownload = () => {
    const docName = documentName ? documentName : name;
    // console.log(src, docName);
    saveAs(src, docName);
  };

  return (
    <Box>
      <ImageWrapper>
        <object
          data={src}
          className="image"
          useMap={String(name).split(".")[0]}
        ></object>
        {deleteIcon && (
          <Box className="close_box">
            <Close
              sx={{
                fontSize: "15px",

                cursor: "pointer",
                color: "rgba(241, 15, 15, 1)",
              }}
              onClick={() => handleDelete()}
            />
          </Box>
        )}
        <Box className="download_box">
          <IconButton onClick={handleDownload}>
            <DownloadIcon sx={{ color: "#fff", fontSize: "25px" }} />
          </IconButton>
        </Box>
      </ImageWrapper>
      <Typo sx={{ width: "100px" }}>
        {documentName ? documentName : String(name).split(".")[0]}
      </Typo>
    </Box>
  );
};

export default PreviewDownloadImage;
const Typo = styled(Typography)`
  width: 100px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 5px;
`;
const ImageWrapper = styled(Box)`
  position: relative;
  height: 100px;
  width: 100px;
  background-color: #f5f5f5;
  transition: 0.5s ease;
  cursor: pointer;
  border: 1px solid rgba(182, 200, 214, 1);
  :hover .download_box {
    opacity: 1;
  }
  .image {
    height: 100%;
    width: 100%;
    object-fit: contain;
    overflow: hidden;
  }
  .download_box {
    z-index: 5;
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(8, 76, 132, 0.75);
    z-index: 2;
  }
  .close_box {
    z-index: 6;
    position: absolute;
    top: 0;
    right: 0;
    width: 20.82px;
    height: 20.82px;
    background: #ffd7d7;
    border: 0.699798px solid #ffb4b4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -12px;
    margin-right: -12px;
  }
`;
