import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { styled, Box,} from "@mui/material";
import { SIZES_CONSTANTS } from "../utils/constants";
import Navbar from "../components/Navbar";
const Layout = () => {
  return (
    <>
    <StyledWrapper >
      {/* sidebar  */}
      <div className="left_sidebar">
        <Sidebar />
      </div>

      <div>
        {/* navigation screens */}
        <Navbar />
        <div className="right_side__content">
          <Outlet />
        </div>
      </div>
    </StyledWrapper>
    </>
  );
};

export default Layout;
const StyledWrapper = styled(Box)`
  height: 100vh;
  display: flex;
  overflow:hidden;
  .left_sidebar {
    width: ${SIZES_CONSTANTS.sidebar_width};
    height: 100%;
    //background: linear-gradient(198deg, #6be1c5 -17.44%, #31b696 103.72%); 
    //background: linear-gradient(-25deg ,#6be1c5 85% , #31b696 85% );
    //background: linear-gradient(198deg, #6BE1C5 -17.44%, #31B696 103.72%);
    background: linear-gradient(-25deg ,#6BE1C5 80% , #31B696 8% );
  }
  .right_side__content {
    width: calc(100vw - ${SIZES_CONSTANTS.sidebar_width});
    overflow-y: auto;
    margin-top: ${SIZES_CONSTANTS.navbarHeight};
    height: calc(100vh - ${SIZES_CONSTANTS.navbarHeight});
    background: #F2F6F6;
  }
  
`;
