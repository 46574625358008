import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../layout";
import { PATH_CONSTANTS } from "../utils/constants";
import Dashboard from "../pages/dashboard/Dashboard";
import StaffList from "../pages/staffList/StaffList";
import UserList from "../pages/userList/UserList";
import Calendar from "../pages/auth/Calendar";
import Events from "../pages/event/Events";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import AdminProtectedRoute from "./AdminProtectedRoute";
import RouteNotFound from "../pages/404";
import AuthProtectedRoute from "./AuthProtectedRoute";
import Upload from "../pages/uploads/Upload";
// import LoginPage from "../modules/auth/login";

export const MainRoute = () => {
  return (
      <Routes>
        <Route element={<RouteNotFound />} path="*" />
          {/* <Route element={<LoginPage />} path="/testlogin" /> */}
        <Route
          element={
            <AuthProtectedRoute>
              <Login />
            </AuthProtectedRoute>
          }
          path={PATH_CONSTANTS.login}
        />
        <Route
          element={
            <AuthProtectedRoute>
              <Register />
            </AuthProtectedRoute>
          }
          path={PATH_CONSTANTS.register}
        />
        <Route
          element={
            <AdminProtectedRoute>
              <Layout />
            </AdminProtectedRoute>
          }
          path={PATH_CONSTANTS.dashboard}
        >
          <Route element={<Dashboard />} path={PATH_CONSTANTS.dashboard} />
          <Route element={<StaffList />} path={PATH_CONSTANTS.staffList} />
          <Route element={<UserList />} path={PATH_CONSTANTS.userList} />
          <Route element={<Calendar />} path={PATH_CONSTANTS.calendar} />
          <Route element={<Upload />} path={PATH_CONSTANTS.upload} />
        </Route>
      </Routes>
  );
};
