import React from "react";
import MaterialReactTable from "material-react-table";
import styled from "styled-components";
import { useMediaQuery } from "@mui/material";


const CommonTable1 = ({
  columns = [],
  data = [],
  maxHeight = "65vh",
  ...props
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      pageCount={20}
      enableStickyHeader={true}
      enablePagination={true}
      enableTopToolbar={true}
      enableBottomToolbar={false}
      muiTableProps={{
        sx: {
          tableLayout: isMobile ? "auto" : "fixed",
        },
      }}
      muiTablePaperProps={{
        sx: {
          boxShadow: 0,
          border: "1px solid #B6C8D6",
          borderRadius: "10px",
        },
      }}
      muiTopToolbarProps={{
        sx: {
          borderRadius: "10px 10px 0 0",
          backgroundColor: "#f6f8fc",
        },
      }}
      muiTableContainerProps={{
        sx: {
          maxHeight: maxHeight,
          overflowY: "auto",
          position:"relative",
          borderRadius: "0 0 10px 10px",
          "&::-webkit-scrollbar": {
            width: 10,
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-track": {
            border: "1px solid #48657c",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#465f72",
            borderRadius: "10px",
          },
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          fontSize: isMobile ? ".9rem" : "12px",
          color: "#0C2F49",
        },
      }}
      muiTableHeadCellProps={{
        sx: {
          fontWeight: "normal",
          fontSize: isMobile ? "1rem" : "14px",
          color: "#0C2F49",
          textAlign: "center",
          marginX: "auto",
          marginY: "auto",
        },
      }}
      muiTableHeadRowProps={{
        sx: {
          height: "50px",
          width: "100%",
          backgroundColor: "#F6F8FC",
          
        },
      }}
      enableColumnFilters
      muiTableBodyProps={{
        sx: {
          fontSize: "14px",
        },
      }}
      {...props}
    />
  );
};

export default CommonTable1;

const TableWrapper = styled.div`
  width: 100%;
  margin: auto;
  border: "1px solid #B6C8D6";
  border-radius:10px;
  overflow:hidden;
  //padding-top:2rem;
`;