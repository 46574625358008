import { useMutation } from "react-query";
import { addFrame } from "../../../services/auth";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import uploadFile from "../../../utils/uploadFile";
import { toast } from "react-toastify";
import { Box, Button, Stack } from "@mui/material";
import PreviewDownloadImage from "../../../common/PreviewDownloadImage";
import { LoadingButton } from "@mui/lab";
import CommonInputSmall from "../../../common/CommonInputSmall";

const FrameForm = ({ refetch, setOpen }) => {
  const initialValues = {
    themeColor: "",
    width: "",
    height: "",
    sizeFor: "",
    framePic: "",
    tags: ["holi", "diwali"],
  };

  /// Add Frame Mutation
  const { mutate } = useMutation(addFrame, {
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      refetch();
      setOpen(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
      console.log("error while adding frame", error.response.data.message);
    },
  });
  const { values, handleChange, handleSubmit, setValues } = useFormik({
    initialValues,
    onSubmit: (formData, action) => {
      mutate(formData);
    },
  });
  const [loading, setLoading] = useState(false);

  const userDetail = useSelector((store) => store.auth.userDetail);
  let userDocId = userDetail?._id;

  const handleUpload = async (e) => {
    setLoading(true);

    try {
      let s3FilePath = `frame/${userDocId}/${e.target.files[0].name}`;
      let attachmentUrl = await uploadFile(
        e.target.files[0],
        s3FilePath,
        e.target.files[0].type
      );
      setValues((prev) => ({ ...prev, framePic: attachmentUrl.fileURL }));
      console.log("attachmentUrl", attachmentUrl);
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  const handleTagChange = (e) => {
    const inputValue = e.target.value;
    const tags = inputValue.split(",").map((tag) => tag.trim());
    setValues((prev) => ({ ...prev, tags: tags }));
  };

  const handleDeleteImage = (img) => {
    setValues((prev) => ({ ...prev, framePic: "" }));
  };

  return (
    <Box sx={{ p: 3 }} component={"form"} onSubmit={handleSubmit}>
      {/* uPLOd Image start */}
      {values.framePic ? (
        <PreviewDownloadImage
          src={values.framePic}
          deleteIcon={true}
          handleDelete={() => handleDeleteImage(values.framePic)}
        />
      ) : (
        <LoadingButton
          variant="outlined"
          component="label"
          color="secondary"
          size="large"
          sx={{
            mt: 1,
            textTransform: "capitalize",
            px: 2,
            py: 0.5,
            borderRadius: "20px",
            width: "123.091px",
            height: "122.035px",
          }}
          loading={loading}
        >
          <img src={"./upload-icon.png"} alt="upload" />
          <input hidden type="file" onChange={handleUpload} accept="image/*" />
        </LoadingButton>
      )}
      {/*  UploD IMAGE END HERES */}
      <Stack spacing={2} sx={{ py: 2 }} direction={"row"} justifyContent={""}>
        <Box width="50%">
          <CommonInputSmall
            id="theme-color"
            name="themeColor"
            label="Theme Color"
            type="text"
            value={values.themeColor}
            handeler={handleChange}
          />
        </Box>
        <Box width="25%">
          <CommonInputSmall
            id="sixw-width"
            name="width"
            label="Size(W)"
            type="text"
            value={values.width}
            handeler={handleChange}
          />
        </Box>
        <Box width="25%">
          <CommonInputSmall
            id="size-height"
            name="height"
            label="Size(H)"
            type="text"
            value={values.height}
            handeler={handleChange}
          />
        </Box>
      </Stack>
      <Stack spacing={2} sx={{ py: 2 }} direction={"row"} justifyContent={""}>
        <Box width="50%">
          <CommonInputSmall
            id="size-for"
            name="sizeFor"
            label="Size For"
            type="text"
            value={values.sizeFor}
            handeler={handleChange}
          />
        </Box>
        <Box width="50%">
          <CommonInputSmall
            id="tag"
            label="Tag"
            type="text"
            name="tags"
            value={values.tags}
            handeler={handleTagChange}
          />
        </Box>
      </Stack>
      <Stack direction="row" justifyContent={"flex-end"}>
        <Button
          type="submit"
          sx={{
            width: "177px",
            p: 1,
            borderRadius: "10px",
            background: " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
            flexShrink: 0,
          }}
          variant="contained"
          size="small"
        >
          Add Frame
        </Button>
      </Stack>
    </Box>
  );
};
export default FrameForm;
