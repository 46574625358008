import {
  Box,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material";
const CommonInputSmall = (props) => {
  const [value, setValue] = useState(props.value);
  return (
    <>
      <StyledWrapper>
        <Box className="form-control">
          <label className="label" htmlFor={props.id}>
            {props.label}
          </label>
          <br />
          {props.handeler ?
          <input
            name={props.name || props.label || ""}
            className="input"
            type={props.type}
            placeholder={props.label}
            value={props.value}
            onChange={props.handeler}
            disabled={props?.disabled}
          />
          :
          <input
            name={props.name || props.label || ""}
            className="input"
            type={props.type}
            placeholder={props.label}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            disabled={props?.disabled}
          />
         }
        </Box>
      </StyledWrapper>
    </>
  );
};
export const CommonTextAriaSmall = (props) => {

  return (
    <>
      <StyledWrapper>
        <Box className="form-control">
          {/* <FormControl variant="outlined">
              <TextField
                variant="outlined"
                className="input"
                endAdornment={
                  <InputAdornment position="start">&#9776;</InputAdornment>
                }
              />
            </FormControl> */}

          <label className="label" htmlFor={props.id}>
            {props.label}
          </label>
          <br />
        
          <textarea rows="4"
            className="input"
            type={props.type}
            placeholder={props.label}
            value={props?.value}
            name={props?.name}
            onChange={props?.handeler}
            disabled={props?.disabled}
          />
        </Box>
      </StyledWrapper>
    </>
  );
};

export default CommonInputSmall;

const StyledWrapper = styled(Box)`
  margin-top: 10px;
  text-align: left;
  .label {
    color: #020c0a;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    padding: 10px 0;
    margin: 10px 0;
    opacity: 0.75;
    outline: none;
  }
  .input {
    width:100%;
    box-sizing:border-box;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.1);
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(61, 57, 137, 0.05);
    letter-spacing: 0.4px;
    padding:12px;
    margin-top: 10px;
    outline: none;
  }
  .input["disabled"]{
    background: #ccc;
  }
`;