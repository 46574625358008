import {
  Button,
  Divider,
  IconButton,
  Modal,
  Stack,
  Typography,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  Drawer,
} from "@mui/material";

import React, { useMemo, useState } from "react";
import styled from "styled-components";
import CommonTable1 from "../../common/CommanTable";
import { addPhoto, getPhoto } from "../../services/auth";
import { useMutation, useQuery } from "react-query";
import formatDate from "../../common/Formater";
import CommonInputSmall from "../../common/CommonInputSmall";
import CustomSelector from "../../common/CustomSelector";

import CommonActionPhoto from "./CommonActionPhoto";
import { useFormik } from "formik";
import TableFilterUpload from "./CommonFilterTable";
import { toast } from "react-toastify";
// Style for PopUp Model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  backgroundColor: "#fff",
  boxShadow: 24,
  borderRadius: "10px",
  width: "700.638px",
};

const PhotoTable = () => {
  // Apply Filter
  const [showHide, setShowHide] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowHide(open);
  };
  // That is not completed yet , not Api niether figma
  //Model Opening States Handeling
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // For refreshing the data
  let refresh;

  // Form Getting States Handeling
  const [data, setData] = useState([]);
  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, index) => index + 1,
        id: "rowIndex", // This is a unique ID (required)
        Cell: ({ cell }) => {
          return (
            <div>
              {cell.row.index + 1 > 9
                ? cell.row.index + 1
                : `0${cell.row.index + 1}`}
            </div>
          );
        },
        size: 10,
      },
      {
        accessorKey: "eventName",
        header: "Event Name",
        size: 100,
      },
      {
        accessorKey: "createdOn", //normal accessorKey
        header: "Created On",
        size: 100,
        Cell: ({ cell }) => {
          let value = cell.getValue();
          return <div>{formatDate(value)}</div>;
        },
      },
      {
        accessorKey: "date",
        header: "Date",
        size: 100,
        Cell: ({ cell }) => {
          let value = cell.getValue();
          return <div>{formatDate(value)}</div>;
        },
      },
      {
        accessorKey: "updatedOn",
        header: "Updated On",
        size: 150,
        Cell: ({ cell }) => {
          let value = cell.getValue();
          return <div>{formatDate(value)}</div>;
        },
      },
      {
        accessorKey: "tags",
        header: "Tags",
        size: 150,
        Cell: ({ cell, index }) => (
          <>
            {cell?.row?.original?.tags.map((tag, i) => (
              <span key={i}>{tag}, </span>
            ))}
          </>
        ),
      },
      {
        header: "Action",
        size: 100,
        Cell: ({ cell, index }) => (
          <CommonActionPhoto refresh={refresh} event={cell?.row?.original} />
        ),
      },
    ],
    []
  );
  const { refetch } = useQuery({
    queryKey: "events",
    queryFn: () => getPhoto(),
    onSuccess: (events) => {
      setData(events.data.list);
      console.log("@PhotoTable :getting data of photos", events.data.list);
    },
    onError: (error) => {
      console.log("@PhotTable : error getting photos ", error);
    },
  });
  refresh = refetch;
  return (
    <StyledWrapper>
      {/*Main Menu*/}
      <Stack
        direction={"row"}
        sx={{ justifyContent: "flex-end", padding: "20px 0" }}
        spacing={2}
      >
        <Button
          variant="contained"
          className="button-add-frame"
          onClick={handleOpen}
        >
          + Add Video
        </Button>
      </Stack>
      {/*Data Table*/}
      <CommonTable1 data={data} columns={columns} />
      {/* Model Box */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              px: 2,
              py: 1,
            }}
          >
            <Typography varient={"h4"} sx={{ textTransform: "capitalize" }}>
              Add Video
            </Typography>
            <IconButton sx={{ p: 0, margin: 0 }} onClick={handleClose}>
              <img alt="close" src="/close_filter.png" />
            </IconButton>
          </Stack>
          <Divider />
          <PhotoVideoForm refresh={refetch} close={handleClose} />
        </Box>
      </Modal>
    </StyledWrapper>
  );
};

export default PhotoTable;

const PhotoVideoForm = ({ refresh, close }) => {
  const [date, setDate] = useState("true");

  const initialValues = {
    date: "",
    eventName: "",
    language: "Hindi",
    tags: ["festival", "holi"],
    category: "trending",
    subCategory: "",
    phhoto: "",
  };

  /// Add Frame Mutation
  const { mutate } = useMutation(addPhoto, {
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      refresh();
      close();
      console.log(" adding frame successfully", data);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
      console.log("error while adding frame", error.response.data.message);
    },
  });

  // Formik form Adding photo diffet types
  const { values, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit: (data) => {
      console.log("submitted", values);
      let body = {
        photo: "",
      };

      if (date === "true") {
        body.withDate = true;
        body.date = values.date;
        body.eventName = values.eventName;
        body.language = values.language;
        body.tags = values.tags;
      } else {
        body.withDate = false;
        if (values.category === "quotes") {
          body.category = values.category;
          body.subCategory = values.subCategory;
          body.name = values.name;
          body.language = values.language;
          body.tags = values.tags;
        } else if (values.category === "trending") {
          body.category = values.category;
          body.trendName = values.trendName;
          body.language = values.language;
          body.tags = values.tags;
        } else if (values.category === "politics") {
          body.category = values.category;
          body.partyName = values.partyName;
          body.name = values.name;
          body.language = values.language;
          body.tags = values.tags;
          body.state = values.state;
        }
      }
      console.log(
        "Consling Body accoring to from type",
        body.withDate,
        body.category,
        body
      );
      mutate(body);
    },
  });

  //state.category ->trending
  return (
    <Box sx={{ px: 3 }}>
      <Stack direction={"row"}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          sx={{ marginBottom: "10px" }}
          value={date}
          onChange={(e) => setDate(e.target.value)}
        >
          <FormControlLabel
            value={"true"}
            control={<Radio />}
            label="With Date"
          />
          <FormControlLabel
            value={"false"}
            control={<Radio />}
            label="Without Date"
          />
        </RadioGroup>
      </Stack>

      <Stack
        sx={{
          width: "123.091px",
          height: "122.035px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "20px",
          border: "1px solid rgba(21, 70, 58, 0.20)",
          background: "rgba(90, 212, 183, 0.10)",
        }}
      >
        <img wisth="72px" height="69px" src="./upload-icon.png" alt="upload" />
      </Stack>

      <Box component={"form"} onSubmit={handleSubmit}>
        {date === "true" ? (
          <>
            <Stack spacing={2} direction={"row"}>
              <Box width="50%">
                <CommonInputSmall
                  name="date"
                  label="Date"
                  type="date"
                  value={values?.date}
                  handeler={handleChange}
                />
              </Box>
              <Box width="50%">
                <CommonInputSmall
                  name="eventName"
                  label="Event Name"
                  value={values?.eventName}
                  handeler={handleChange}
                />
              </Box>
            </Stack>
            <Stack
              spacing={2}
              sx={{ py: 2 }}
              direction={"row"}
              justifyContent={""}
            >
              <Box width="50%">
                <CustomSelector
                  label="Languages"
                  name="language"
                  value={values.language}
                  handeler={handleChange}
                  values={["English", "Hindi"]}
                />
              </Box>
              <Box width="50%">
                <CommonInputSmall
                  name="tags"
                  label="Tags"
                  value={values?.tags}
                  handeler={handleChange}
                />
              </Box>
            </Stack>
          </>
        ) : (
          <>
            <Stack spacing={2} direction={"row"}>
              <Box width="50%">
                <CustomSelector
                  label="Languages"
                  name="language"
                  value={values.language}
                  handeler={handleChange}
                  values={["English", "Hindi"]}
                />
              </Box>
              <Box width="50%">
                <CustomSelector
                  label="Cetogories"
                  name={"category"}
                  value={values.category}
                  handeler={handleChange}
                  values={["quotes", "politics", "trending", "business"]}
                />
              </Box>
            </Stack>
            <Stack
              spacing={2}
              sx={{ py: 1 }}
              direction={"row"}
              justifyContent={""}
            >
              {values.category === "quotes" && (
                <Box width="50%">
                  <CommonInputSmall
                    name="subCategory"
                    label="Sub Category"
                    value={values?.subCategory}
                    handeler={handleChange}
                  />
                </Box>
              )}
              {values.category === "politics" && (
                <Box width="50%">
                  <CommonInputSmall
                    name="partyName"
                    label="Party Name"
                    value={values?.partyName}
                    handeler={handleChange}
                  />
                </Box>
              )}
              {values.category === "trending" && (
                <Box width="50%">
                  <CommonInputSmall
                    name="trendName"
                    label="Trend Name"
                    value={values?.trendName}
                    handeler={handleChange}
                  />
                </Box>
              )}
              {values.category === "trending" ||
                (values.category === "quotes" && (
                  <Box width="50%">
                    <CommonInputSmall
                      name="name"
                      label="Name"
                      value={values?.name}
                      handeler={handleChange}
                    />
                  </Box>
                ))}

              {values.category === "politics" && (
                <Box width="50%">
                  <CustomSelector
                    label="state"
                    name={"state"}
                    value={values.state}
                    handeler={handleChange}
                    values={["Up", "Bihar"]}
                  />
                </Box>
              )}
            </Stack>
            <Stack
              spacing={2}
              sx={{ py: 1 }}
              direction={"row"}
              justifyContent={""}
            >
              {values.category === "politics" && (
                <Box width="50%">
                  <CommonInputSmall
                    name="name"
                    label="Name"
                    value={values?.name}
                    handeler={handleChange}
                  />
                </Box>
              )}

              <Box width="50%">
                <CommonInputSmall
                  name="tags"
                  label="Tags"
                  value={values?.tags}
                  handeler={handleChange}
                />
              </Box>
            </Stack>
          </>
        )}
        <Stack direction="row" justifyContent={"flex-end"}>
          <Button
            type="submit"
            sx={{
              width: "177px",
              p: 1,
              borderRadius: "10px",
              background: " linear-gradient(180deg, #1B5447 0%, #144439 100%)",
              flexShrink: 0,
              marginBottom: "40px",
            }}
            variant="contained"
            size="small"
          >
            Add Video
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

const StyledWrapper = styled(Box)`
  .over-view {
    width: 100.35px;
    border-radius: 10px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.3);
    background: #fff;
  }
  .doc-type {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: capitalize;
  }
  .active .over-view {
    background-color: #5bd5b9;
  }
  .active .doc-type {
    color: #fff;
  }
  a {
    text-decoration: none;
  }
  .button-filter {
    width: 122.823px;
    height: 36.062px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #1a5346;
    text-transform: capitalize;
  }
  .button-add-frame {
    text-transform: capitalize;
    width: 173.371px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.05);
    background: #1a5346;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  .model-upload-button {
    width: 123.091px;
    height: 122.035px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid rgba(21, 70, 58, 0.2);
    background: rgba(90, 212, 183, 0.1);
  }
`;
