import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useMemo, useState } from "react";

// import MyMenu from "../common/MyMenu1";
import CommonTable1 from "../../common/CommanTable";
import { useQuery } from "react-query";
import {
  getOnboardedUserCount,
  getUserInfo,
  getUserList,
} from "../../services/auth";
import formatDate from "../../common/Formater";
import { useFormik } from "formik";
import MyMenu from "./MyMenu";

const UserList = () => {
  const [data, setData] = useState([]);
  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, index) => index + 1,
        id: "rowIndex", // This is a unique ID (required)
        Cell: ({ cell }) => {
          return (
            <div>
              {cell.row.index + 1 > 9
                ? cell.row.index + 1
                : `0${cell.row.index + 1}`}
            </div>
          );
        },
        size: 10,
      },
      {
        accessorKey: "firstName",
        header: "User Name",
        size: 150,
      },
      {
        accessorKey: "email",
        header: "Email Id",
        size: 150,
      },
      {
        accessorKey: "mobileNumber", //normal accessorKey
        header: "Mobile Number",
        size: 150,
      },
      {
        accessorKey: "designation",
        header: "Category",
        size: 150,
      },
      {
        accessorKey: "createdOn",
        header: "Account Created",
        size: 150,
        Cell: ({ cell }) => {
          let value = cell.getValue();
          return <div>{formatDate(value)}</div>;
        },
      },
    ],
    []
  );

  // getting user Statics (Total Users)
  const { data: user_statics } = useQuery({
    queryKey: "getOnboardedUserCount",
    queryFn: () => getOnboardedUserCount(),
    onSuccess: (userStatics) => {
      console.log("@UserList : getting users statics", userStatics);
    },
    onError: (error) => {
      console.log("@UserList : error getting users statics", error);
    },
  });

  // Getting User List Data
  const { isLoading: userListLoading, refetch: RefetchUser } = useQuery({
    queryKey: "user_list_data",
    queryFn: () => getUserList(),
    onSuccess: (userList) => {
      setData(userList.data.list);
      console.log("@UserList : getting Users list", userList.data.list);
    },
    onError: (error) => {
      console.log("@UserList : error while getting Users list ", error);
    },
  });

  if (userListLoading) {
    return <div>loading...</div>;
  }

  return (
    <StyledWrapper maxWidth={"100%"}>
      <Stack direction={{ sm: "row", ms: "column" }}>
        <Stack
          width={{ sm: "100%", ms: "50%" }}
          direction={{ sm: "row", ms: "column" }}
          spacing={2}
        >
          <Paper square className="over-view">
            <Typography variant="body2" className="over-view-label">
              Total User
            </Typography>
            <Typography component={"h4"} className="over-view-data">
              {user_statics?.data?.list?.totalusers}
            </Typography>
          </Paper>
          <Paper square className="over-view">
            <Typography variant="body2" className="over-view-label">
              This Month
            </Typography>
            <Typography component={"h4"} className="over-view-data">
              {user_statics?.data?.list?.currentMonthUsers}
            </Typography>
          </Paper>
          <Paper square className="over-view">
            <Typography variant="body2" className="over-view-label">
              This Year
            </Typography>
            <Typography component={"h4"} className="over-view-data">
              {user_statics?.data?.list?.countCurrentYearUsers}
            </Typography>
          </Paper>
        </Stack>
      </Stack>
      <MyMenu
        name="User List"
        RefetchUser={RefetchUser}
        setData={setData}
      ></MyMenu>
      {/* Please Check Once : sticky header is not working in this components but working in Upoload */}
      <CommonTable1
        columns={columns}
        data={data}
        //maxHeight="50vh"
      />
    </StyledWrapper>
  );
};

export default UserList;

const StyledWrapper = styled(Box)`
  padding: 20px 30px;
  box-sizing: border-box;
  max-width: 100%;

  .label {
    color: #020c0a;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    padding: 10px 0;
    margin: 10px 0;
    opacity: 0.75;
    outline: none;
  }

  .over-view {
    padding: 19.6px;
    width: 166.595px;
    height: 80.394px;
    box-sizing: border-box;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(2, 12, 10, 0.1);
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(61, 57, 137, 0.05);
  }

  .over-view-label {
    color: #020c0a;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    opacity: 0.5;
  }

  .over-view-data {
    color: #020c0a;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .send-reminder {
    border-radius: 10px;
    border: 1px solid #1a5346;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(61, 57, 137, 0.05);
    width: 184.639px;
    height: 50px;
    flex-shrink: 0;
    color: #1a5346;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 9px 16px;
  }

  table {
    border-radius: 10px;
    overflow: hidden;
  }

  .over-view-data-title {
    color: #020c0a;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 20px 0;
  }

  .filter-button {
    width: 122.823px;
    height: 36.062px;
    flex-shrink: 0;
    color: #fff;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-size: 12px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    border-radius: 10px;
    background: #1a5346;
    text-transform: capitalize;
  }

  .export-label {
    color: #020c0a;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
  }
`;
